// import React, { useEffect, useRef } from 'react';
// import styled from 'styled-components';
// import './About.css';
// import { PieChart, Pie, Cell, Tooltip, Legend, BarChart, Bar, XAxis, YAxis, CartesianGrid } from 'recharts';
// import allowedspots from '../assets/allowedspots.png'

// // 스타일 컴포넌트 정의
// const ChartGroup = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: center;
//   gap: 20px;
  
//   @media (max-width: 1200px) {
//     justify-content: space-around;
//   }

//   @media (max-width: 900px) {
//     flex-direction: column;
//     align-items: center;
//   }
// `;

// const ChartContainer = styled.div`
//   flex: 1 1 300px;
//   max-width: 400px;
//   min-width: 300px;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// `;


// // 스타일 컴포넌트 정의
// const TenderContainer = styled.div`
//   overflow: hidden;
//   width: 100%;
//   display: flex;
//   flex-direction: column; /* 세로로 두 행 배치 */
//   gap: 20px; /* 두 행 간 간격 */
// `;

// const TenderRow = styled.div`
//   display: flex;
//   gap: 20px;
//   justify-content: center;
//   white-space: nowrap; /* 카드들이 한 줄로 배치되도록 설정 */
// `;

// const TenderBox = styled.div`
//   flex: 0 0 300px; /* 각 카드의 고정 너비 */
//   background-color: #333;
//   border-radius: 10px;
//   padding: 20px;
//   color: white;
//   text-align: center;
// `;

// const TenderStatus = styled.div`
//   font-weight: bold;
//   color: ${(props) => (props.status === '공모중' ? '#ff8c00' : '#aaa')};
// `;


// // 공모 데이터 리스트
// const tenders = [
//   { status: '공모중', title: '[서문 야시장]', description: '음식 매대 지원 및 푸드 트럭 공모' },
//   { status: '공모완료', title: '[대구 마라톤 대회]', description: '푸드트럭 참가 업체 모집 공고' },
//   { status: '공모중', title: '[칠성 야시장]', description: '푸드트럭 식품 셀러를 모집' },
//   { status: '공모중', title: '[서울 야시장]', description: '푸드트럭 신규 셀러 모집' },
//   { status: '공모완료', title: '[부산 해운대 축제]', description: '푸드트럭 참가 신청 마감' },
//   { status: '공모중', title: '[광주 청년 마켓]', description: '청년 창업 푸드트럭 모집' },
//   { status: '공모완료', title: '[인천 바다 축제]', description: '푸드트럭 운영 신청 마감' },
//   { status: '공모중', title: '[대전 푸드 페스티벌]', description: '참가 푸드트럭 모집' },
//   { status: '공모완료', title: '[울산 야시장 이벤트]', description: '푸드트럭 이벤트 참가자 모집' },
//   { status: '공모중', title: '[경주 문화 축제]', description: '푸드트럭 참가 업체 모집' },
//   { status: '공모완료', title: '[제주 푸드 아일랜드]', description: '푸드트럭 셀러 신청 마감' },
//   { status: '공모중', title: '[춘천 닭갈비 축제]', description: '푸드트럭 참가 셀러 모집' },
//   { status: '공모완료', title: '[수원 야시장]', description: '푸드트럭 운영 공모 마감' },
//   { status: '공모중', title: '[전주 한옥마을]', description: '푸드트럭 신규 입점 모집' }
// ];


// // 데이터 및 컴포넌트 정의
// function About() {

//   const tenderListRef1 = useRef(null);
//   const tenderListRef2 = useRef(null);

//   useEffect(() => {
//   const scrollRow = (listRef, speed, offset) => {
//     let scrollAmount = offset;

//     const scroll = () => {
//       scrollAmount -= speed; // 스크롤 속도 조정
//       if (Math.abs(scrollAmount) >= listRef.current.scrollWidth / 2) {
//         scrollAmount = 0; // 리스트 끝에서 초기화
//       }
//       listRef.current.style.transform = `translateX(${scrollAmount}px)`;
//       requestAnimationFrame(scroll);
//     };

//     scroll();
//   };

//   // 첫 번째 행: 정규 속도
//   scrollRow(tenderListRef1, 2, 0); // offset은 0
//   // 두 번째 행: 더 빠른 속도와 시작 오프셋 적용
//   scrollRow(tenderListRef2, 2, -150); // 두 번째 행은 약간 뒤에서 시작
// }, []);

//   // 데이터를 두 그룹으로 나누기
//   const group1 = tenders.filter((_, index) => index % 2 === 0); // 짝수 인덱스
//   const group2 = tenders.filter((_, index) => index % 2 !== 0); // 홀수 인덱스


//   // 첫 번째 파이차트 데이터
//   const data1 = [
//     { name: '창업의향X', value: 27.2 },
//     { name: '창업준비중', value: 16.0 },
//     { name: '창업의향O', value: 56.8 },
//   ];

//   // 두 번째 파이차트 데이터
//   const data2 = [
//     { name: 'IT●정보통신', value: 14.6 },
//     { name: '도소매', value: 17.9 },
//     { name: '숙박●음식', value: 31.0 },
//   ];

//   // 막대 차트 데이터
//   const barData = [
//     { name: '2015년', 푸드트럭개수: 3 },
//     { name: '2017년', 푸드트럭개수: 316 },
//     { name: '2019년', 푸드트럭개수: 1915 },
//     { name: '2022년', 푸드트럭개수: 2001 },
//     { name: '2024년', 푸드트럭개수: 2843 },
//   ];

//   const COLORS = ['#ff7710', '#FFF', '#FFBB28'];
//   const COLORS2 = ['#FFBB28', '#ff7710', '#FFF'];

//   return (
//     <section id="about" className="about-section">
//       <h4>About Us</h4>
//       <h2 className="subtitle">왜 푸드트럭 사업을 선택했는가?</h2>
//       <div className="info-sections">
//         <div className="info-part">
//           <div class="info-container">
//             <div class="number">1</div>
//             <h5>"전국 푸드트럭 현황과 문제점"</h5>
//           </div>
//           <p>푸드트럭 사업은 창업 비용이 낮고, 초기 자금 부담이 적으며, 창의적인 메뉴를 통해 고유한 브랜드를 쉽게 만들 수 있는 매력적인 창업 형태입니다. 최근 창업 인식 조사와 창업 희망 분야에 대한 통계를 살펴보면, 많은 사람들이 푸드트럭을 매력적인 창업 아이템으로 생각하고 있다는 것을 확인할 수 있습니다.</p>
//           <br></br>
//           <br></br>
//           {/* 차트들 묶음 */}
//           <ChartGroup>
//             {/* 첫 번째 파이 차트 */}
//             <ChartContainer>
//               <h3>&nbsp; &nbsp;  MZ세대 미취업 청년의<br></br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;창업 인식 조사 </h3>
//               <PieChart width={300} height={300}>
//                 <Pie
//                   data={data1}
//                   dataKey="value"
//                   nameKey="name"
//                   cx="50%"
//                   cy="50%"
//                   innerRadius={50}
//                   outerRadius={80}
//                   fill="#8884d8"
//                   paddingAngle={5}
//                   label
//                 >
//                   {data1.map((entry, index) => (
//                     <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
//                   ))}
//                 </Pie>
//                 <Tooltip />
//                 <Legend />
//               </PieChart>
//               <p>출처- 한국경영자총협회</p>
//             </ChartContainer>

//             {/* 막대 차트 */}
//             <ChartContainer>
//               <h3>&nbsp; &nbsp;  전국 푸드트럭 현황 </h3><br></br><br></br>
//               <BarChart width={300} height={300} data={barData}>
//                 <CartesianGrid strokeDasharray="3 3" />
//                 <XAxis dataKey="name" />
//                 <YAxis />
//                 <Tooltip />
//                 <Legend />
//                 <Bar dataKey="푸드트럭개수" fill="#fe7810" />
//               </BarChart>
//               <p>출처- 식품의약품안전처</p>
//             </ChartContainer>

//             {/* 두 번째 파이 차트 */}
//             <ChartContainer>
//               <h3>&nbsp;&nbsp;  청년 창업 희망자의<br></br> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 창업 희망 분야 </h3>
//               <PieChart width={300} height={300}>
//                 <Pie
//                   data={data2}
//                   dataKey="value"
//                   nameKey="name"
//                   cx="50%"
//                   cy="50%"
//                   innerRadius={50}
//                   outerRadius={80}
//                   fill="#82ca9d"
//                   paddingAngle={5}
//                   label
//                 >
//                   {data2.map((entry, index) => (
//                     <Cell key={`cell-${index}`} fill={COLORS2[index % COLORS2.length]} />
//                   ))}
//                 </Pie>
//                 <Tooltip />
//                 <Legend />
//               </PieChart>
//               <p>출처- 한국경영자총협회</p>
//             </ChartContainer>
//           </ChartGroup>
//           <br></br><br></br>
//           <p>전국적으로 푸드트럭 수는 꾸준히 증가하고 있지만, 특히 대구 지역에서 푸드트럭이 영업 가능한 허가 구역은 여전히 매우 제한적입니다. 아래 지도는 현재 대구에서 푸드트럭이 허가된 구역을 보여주고 있습니다.</p>
//           <br></br>
//           <div className="image-container">
//             <img src={allowedspots} alt="About Us" className="about-image" />
//           </div>
//           <div className="arrow">
//             <p>▼<br></br>▼</p>
//           </div>
//           <div className="highlight-box">
//             <h5>푸드트럭 사업의 개선 필요성</h5>
//             <p>푸드트럭 사업자들이 말한 안정적으로 운영할 수 있는 가장 큰 어려움 중 하나는 영업 가능 장소를 찾는 것이라 합니다. 푸드트럭 허가 구역이나 신규 공고가 있을 때, 이를 알려줄 수 있는 시스템만 갖추어져도 푸드트럭 사업에 큰 변화를 가져올 수 있을 것입니다. 이러한 문제를 해결하기 위해, 우리는 푸드트럭 위치 선정 웹사이트를 개발하게 되었습니다.</p>
//           </div>
//         </div>

//         {/* 무한 스크롤 리스트 */}
//         <div className="info-part">
//           <div className="info-container">
//             <div className="number">2</div>
//             <h5>"최근 공모정보 확인 기능"</h5>
//           </div>
//           <TenderContainer>
//             {/* 첫 번째 행 */}
//             <TenderRow ref={tenderListRef1}>
//               {group1.concat(group1).map((tender, index) => (
//                 <TenderBox key={`group1-${index}`}>
//                   <TenderStatus status={tender.status}>{tender.status}</TenderStatus>
//                   <h3>{tender.title}</h3>
//                   <p>{tender.description}</p>
//                 </TenderBox>
//               ))}
//             </TenderRow>

//             {/* 두 번째 행 */}
//             <TenderRow ref={tenderListRef2}>
//               {group2.concat(group2).map((tender, index) => (
//                 <TenderBox key={`group2-${index}`}>
//                   <TenderStatus status={tender.status}>{tender.status}</TenderStatus>
//                   <h3>{tender.title}</h3>
//                   <p>{tender.description}</p>
//                 </TenderBox>
//               ))}
//             </TenderRow>
//           </TenderContainer>
//         </div>
//       </div>
//     </section>
//   );
// }

// export default About;
import React, { useEffect, useRef }  from 'react';
import styled from 'styled-components';
import './About.css';
import { PieChart, Pie, Cell, Tooltip, Legend, BarChart, Bar, XAxis, YAxis, CartesianGrid } from 'recharts';
import allowedspots from '../assets/allowedspots.png'

// 스타일 컴포넌트 정의
const ChartGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  
  @media (max-width: 1200px) {
    justify-content: space-around;
  }

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ChartContainer = styled.div`
  flex: 1 1 300px;
  max-width: 400px;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TenderContainer = styled.div`
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column; /* 세로로 두 행 배치 */
  gap: 20px; /* 두 행 간 간격 */
`;

const TenderRow = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  white-space: nowrap; /* 카드들이 한 줄로 배치되도록 설정 */
`;

const TenderBox = styled.div`
  flex: 0 0 300px; /* 각 카드의 고정 너비 */
  background-color: #333;
  border-radius: 10px;
  padding: 20px;
  color: white;
  text-align: center;
`;

const TenderStatus = styled.div`
  font-weight: bold;
  color: ${(props) => (props.status === '공모중' ? '#ff8c00' : '#aaa')};
`;
// 공모 데이터 리스트
const tenders = [
  { status: '공모중', title: '[서문 야시장]', description: '음식 매대 지원 및 푸드 트럭 공모' },
  { status: '공모완료', title: '[대구 마라톤 대회]', description: '푸드트럭 참가 업체 모집 공고' },
  { status: '공모중', title: '[칠성 야시장]', description: '푸드트럭 식품 셀러를 모집' },
  { status: '공모중', title: '[서문 야시장]', description: '추가 셀러 모집 공고' },
  { status: '공모완료', title: '[신천 물놀이장]', description: '매점 및 푸드트럭 영업자 모집' },
  { status: '공모중', title: '[두류 워터파크]', description: '푸드트럭 및 야외매점 사용·수익허가' },
  { status: '공모완료', title: '[금호강 바람소리길]', description: '착한먹거리 푸드트럭 모집공고' },
  { status: '공모중', title: '[낙동강 캠핑]', description: '축제장 내 푸드 트럭 영업자 모집 공고' },
  { status: '공모완료', title: '[신천 물놀이장]', description: '매점 및 푸드트럭 영업자 모집' },
  { status: '공모중', title: '[두류 워터파크]', description: '푸드트럭 및 야외매점 사용·수익허가' },
  { status: '공모완료', title: '[금호강 바람소리길]', description: '착한먹거리 푸드트럭 모집공고' },
  { status: '공모중', title: '[낙동강 캠핑]', description: '축제장 내 푸드 트럭 영업자 모집 공고' },
  { status: '공모완료', title: '[대구 마라톤 대회]', description: '푸드트럭 참가 업체 모집 공고' },
  { status: '공모중', title: '[칠성 야시장]', description: '푸드트럭 식품 셀러를 모집' },
  { status: '공모완료', title: '[대구 문화 페스티벌]', description: '푸드트럭 참가 신청 마감' },
];


// 데이터 및 컴포넌트 정의
function About() {

  const tenderListRef1 = useRef(null);
const tenderListRef2 = useRef(null);

useEffect(() => {
  const scrollRow = (listRef, speed, offset) => {
    if (!listRef.current) return; // DOM 요소가 없으면 종료

    let scrollAmount = offset;

    const scroll = () => {
      if (!listRef.current) return; // 계속 확인
      scrollAmount -= speed; // 스크롤 속도 조정
      if (Math.abs(scrollAmount) >= listRef.current.scrollWidth / 2) {
        scrollAmount = 0; // 리스트 끝에서 초기화
      }
      listRef.current.style.transform = `translateX(${scrollAmount}px)`;
      requestAnimationFrame(scroll);
    };

    scroll();
  };

  // DOM 요소가 준비되었는지 확인한 후 호출
  if (tenderListRef1.current && tenderListRef2.current) {
    scrollRow(tenderListRef1, 2, 0); // 첫 번째 행: offset은 0
    scrollRow(tenderListRef2, 2, -150); // 두 번째 행은 약간 뒤에서 시작
  }
}, []);

 // 데이터를 두 그룹으로 나누기
 const group1 = tenders.filter((_, index) => index % 2 === 0); // 짝수 인덱스
 const group2 = tenders.filter((_, index) => index % 2 !== 0); // 홀수 인덱스

  // 첫 번째 파이차트 데이터
  const data1 = [
    { name: '창업의향X', value: 27.2 },
    { name: '창업준비중', value: 16.0 },
    { name: '창업의향O', value: 56.8 },
  ];

  // 두 번째 파이차트 데이터
  const data2 = [
    { name: 'IT●정보통신', value: 14.6 },
    { name: '도소매', value: 17.9 },
    { name: '숙박●음식', value: 31.0 },
  ];

  // 막대 차트 데이터
  const barData = [
    { name: '2015년', 푸드트럭개수: 3 },
    { name: '2017년', 푸드트럭개수: 316 },
    { name: '2019년', 푸드트럭개수: 1915 },
    { name: '2022년', 푸드트럭개수: 2001 },
    { name: '2024년', 푸드트럭개수: 2843 },
  ];

  const COLORS = ['#ff7710', '#FFF', '#FFBB28'];
  const COLORS2 = ['#FFBB28', '#ff7710', '#FFF'];

  return (
    <section id="about" className="about-section">
      <h4>About Us</h4>
      <h2 className="subtitle">왜 푸드트럭 사업을 선택했는가?</h2>
      <div className="info-sections">
        <div className="info-part">
          
          <div class="info-container">
          <div className="top-row">
            <div class="number">1</div>
            <h5>"전국 푸드트럭 현황과 문제점"</h5>
          </div>
          </div>
          <p>푸드트럭 사업은 창업 비용이 낮고 초기 자금 부담이 적으며 창의적인 메뉴를 통해 고유한 브랜드를 쉽게 만들 수 있는 창업 형태입니다. 최근 창업 인식 조사와 창업 희망 분야에 대한 통계를 살펴보면 많은 사람들이 푸드트럭을 매력적인 창업 아이템으로 생각하고 있다는 것을 확인할 수 있습니다.</p>
          <br></br>
          <br></br>
          {/* 차트들 묶음 */}
          <ChartGroup>
            {/* 첫 번째 파이 차트 */}
            <ChartContainer>
              <h3>&nbsp; &nbsp;  MZ세대 미취업 청년의<br></br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;창업 인식 조사 </h3>
              <PieChart width={300} height={300}>
                <Pie
                  data={data1}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  innerRadius={50}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={5}
                  label
                >
                  {data1.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
              <p>출처- 한국경영자총협회</p>
            </ChartContainer>

            {/* 막대 차트 */}
            <ChartContainer>
              <h3>&nbsp; &nbsp;  전국 푸드트럭 현황 </h3><br></br><br></br>
              <BarChart width={300} height={300} data={barData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="푸드트럭개수" fill="#fe7810" />
              </BarChart>
              <p>출처- 식품의약품안전처</p>
            </ChartContainer>

            {/* 두 번째 파이 차트 */}
            <ChartContainer>
              <h3>&nbsp;&nbsp;  청년 창업 희망자의<br></br> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 창업 희망 분야 </h3>
              <PieChart width={300} height={300}>
                <Pie
                  data={data2}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  innerRadius={50}
                  outerRadius={80}
                  fill="#82ca9d"
                  paddingAngle={5}
                  label
                >
                  {data2.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS2[index % COLORS2.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
              <p>출처- 한국경영자총협회</p>
            </ChartContainer>
          </ChartGroup>
          <br></br><br></br>
          <p>전국적으로 푸드트럭 수는 꾸준히 증가하고 있지만, 특히 대구 지역에서 푸드트럭이 영업 가능한 허가 구역은 여전히 매우 제한적입니다. 아래 지도는 현재 대구에서 푸드트럭이 허가된 구역을 보여주고 있습니다.</p>
          <br></br>
          
          <div className="image-container">
            <img src={allowedspots} alt="About Us" className="about-image" />
          </div>
          <div className="arrow">
            <p>▼<br></br>▼</p>
          </div>
          <div className="highlight-box">
            <h5>푸드트럭 사업의 개선 필요성</h5>
            <p>푸드트럭 사업자들이 말한 안정적으로 운영할 수 있는 가장 큰 어려움 중 하나는 영업 가능 장소를 찾는 것이라 합니다. 푸드트럭 허가 구역이나 신규 공고가 있을 때 이를 알려줄 수 있는 시스템만 갖추어져도 푸드트럭 사업에 큰 변화를 가져올 수 있을 것입니다. 이러한 문제를 해결하기 위해 우리는 푸드트럭 위치 선정 웹사이트를 개발하게 되었습니다.</p>
          </div>
        </div>

        {/* 무한 스크롤 리스트 */}
        <div className="info-part">
        <div className="info-container">
        <div className="top-row">
          <div className="number">2</div>
          <h5>"분산된 공모 정보 통합 시스템"</h5>
        </div>
        <p>여러 웹사이트로 분산되어 나타나는 공모 정보를 한 곳에 모았습니다.</p>
      </div>

          <TenderContainer>
            {/* 첫 번째 행 */}
            <TenderRow ref={tenderListRef1}>
              {group1.concat(group1).map((tender, index) => (
                <TenderBox key={`group1-${index}`}>
                  <TenderStatus status={tender.status}>{tender.status}</TenderStatus>
                  <h3>{tender.title}</h3>
                  <p>{tender.description}</p>
                </TenderBox>
              ))}
            </TenderRow>

            {/* 두 번째 행 */}
            <TenderRow ref={tenderListRef2}>
              {group2.concat(group2).map((tender, index) => (
                <TenderBox key={`group2-${index}`}>
                  <TenderStatus status={tender.status}>{tender.status}</TenderStatus>
                  <h3>{tender.title}</h3>
                  <p>{tender.description}</p>
                </TenderBox>
              ))}
            </TenderRow>
          </TenderContainer>
        </div>
      </div>
    </section>
  );
}

export default About;
