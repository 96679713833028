import React, { useEffect, useRef } from 'react';
import './Contest.css';
import image1 from '../assets/im1.PNG';
import image2 from '../assets/im3.PNG';
import image3 from '../assets/im2.PNG';

function Contest() {
  const boxRefs = useRef([]);

  useEffect(() => {
    // Intersection Observer 설정
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // 요소가 화면에 들어오면 'visible' 클래스를 추가
            entry.target.classList.add('visible');
          }
        });
      },
      { threshold: 0.5 } // 50%가 보이면 트리거
    );

    // 각 박스 요소에 대해 Observer 연결
    boxRefs.current.forEach((box) => {
      if (box) observer.observe(box);
    });

    // 컴포넌트가 언마운트될 때 Observer 해제
    return () => observer.disconnect();
  }, []);

  return (
    <div className="conclusion-section">
      <h4 className="conclusion-title">Result</h4>
      <h2 className="conclusion-description">웹사이트의 기대 효과</h2>
      <p className='conclusion'>최적의 푸드트럭 입지를 찾는데 필요한 모든 것을 한 곳에서.<br></br>데이터를 활용한 효율적인 분석으로 성공적인 비즈니스를 위한 첫걸음을 시작할 수 있습니다.</p>
      <div className="conclusion-boxes">
        {/* 첫 번째 박스 */}
        <div
          className="conclusion-box"
          ref={(el) => (boxRefs.current[0] = el)}
          style={{ animationDelay: '0s' }} // 애니메이션 지연 시간 설정
        >
          <img src={image1} alt="규제정보" className="box-image" />
          <div className="highlight-box">
            <h5>상권 분석의 정확성</h5>
            <p className="box-description">
              데이터 기반의 정교한 분석으로 파악된 데이터를 기반으로 최적의 입지를 추천받을 수 있습니다.
            </p>
          </div>
        </div>
        {/* 두 번째 박스 */}
        <div
          className="conclusion-box"
          ref={(el) => (boxRefs.current[1] = el)}
          style={{ animationDelay: '0.5s' }} // 0.5초 지연
        >
          <img src={image2} alt="기대효과" className="box-image" />
          <div className="highlight-box">
            <h5>비즈니스 성과 향상</h5>
            <p className="box-description">
              적합한 입지 선정으로 비즈니스적인 성장과 바로 직결된 매출에 도움을 받을 수 있습니다.
            </p>
          </div>
        </div>
        {/* 세 번째 박스 */}
        <div
          className="conclusion-box"
          ref={(el) => (boxRefs.current[2] = el)}
          style={{ animationDelay: '1s' }} // 1초 지연
        >
          <img src={image3} alt="추가설명" className="box-image" />
          <div className="highlight-box">
            <h5>시간과 비용 절감</h5>
            <p className="box-description">
              효율적인 데이터 제공으로 찾는 시간을 절약하고 불필요한 비용을 줄일 수 있습니다.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contest;
