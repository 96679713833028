// import React, { useEffect } from 'react';
// import './Services.css';
// import AOS from 'aos';
// import 'aos/dist/aos.css'; // AOS 스타일 파일을 import
// import samplevideo from '../assets/samplevideo.mp4'

// function Services() {
//   useEffect(() => {
//     AOS.init({
//       duration: 1000, // 애니메이션 지속 시간 (밀리초 단위)
//       once: false,     
//     });
//   }, []);

//   const processSteps = [
//     {
//       step: 'STEP 1',
//       title: '데이터 수집 및 통합',
//       description:
//         '대구 지역의 상권 분석을 위해 신한카드와 국민카드 매출 데이터를 대구 빅데이터 센터에서 수집합니다. 이 데이터는 동별 매출 정보, 업종별 매출 비율, 시간대별 매출 등을 포함하고 있습니다. 수집된 데이터를 통합하여 모든 카테고리에서 일관된 기준을 유지할 수 있도록 가공합니다. 이를 통해 대구 각 동의 상권 현황을 포괄적으로 분석할 수 있는 기초 데이터를 구축합니다.',
//     },
//     {
//       step: 'STEP 2',
//       title: '데이터 정제 및 전처리',
//       description:
//         '수집한 데이터를 분석 가능한 형태로 가공하는 단계입니다. 누락된 값이나 중복된 데이터를 제거하고, 데이터 형식을 일관되게 통일하여 정확성을 높입니다. 특히, 카드 매출 데이터는 시간대, 업종, 결제 수단 등 다양한 기준으로 나뉘기 때문에 이를 동별로 정리하여 지역적 특성을 명확히 나타낼 수 있도록 전처리합니다.',
//     },
//     {
//       step: 'STEP 3',
//       title: '데이터 분석 및 시각화',
//       description:
//         '정제된 데이터를 바탕으로 다양한 분석 모델을 적용합니다. 시간대별 매출, 업종별 매출 비율, 특정 지역의 소비 패턴 등을 파악하여 직관적인 분석 결과를 도출합니다. 분석된 데이터를 바탕으로 히트맵, 차트, 그래프 등의 시각화를 진행하여 각 동의 매출 특성을 쉽게 파악할 수 있게 합니다. 이 단계에서는 사용자들이 관심을 가질만한 주요 상권 특성을 데이터 기반으로 명확히 보여주는 데 중점을 둡니다.',
//     },
//     {
//       step: 'STEP 4',
//       title: '웹사이트 통합 및 사용자 인터페이스 구성',
//       description:
//         '최종적으로 분석된 데이터를 웹사이트에 통합하여 사용자에게 제공하는 단계입니다. 푸드트럭 창업자가 쉽게 사용할 수 있도록 직관적인 사용자 인터페이스(UI)를 설계하며, 동별 매출 현황과 추천 위치를 한눈에 확인할 수 있는 기능을 구현합니다. 이를 통해 매출이 높은 시간대, 매출이 활발한 동네, 특정 업종이 인기 있는 지역 등을 효율적으로 제공하여 최적의 입지를 추천합니다.',
//     },
//   ];

//   return (
//     <section id="services" className="services-section">
//       <h4>Data</h4>
//       <h2>상권 분석 정보 데이터<br></br> 가공 과정</h2>
//       <p>아래 소개된 4단계의 과정을 거쳐 FoodTruckFinder의<br></br> 상권분석 데이터가 만들어지고 있습니다.</p>
//       <div className="service-list">
//         {processSteps.map((process, index) => (
//           <div
//             key={index}
//             className="service-item"
//             data-aos="fade-up" // AOS 애니메이션 효과 지정
//           >
//             <h4>{process.step}</h4>
//             <h3>{process.title}</h3>
//             <p>{process.description}</p>
//           </div>
//         ))}
//       </div>
//     </section>
//   );
// }

// export default Services;
import React, { useState } from "react";
import "./Services.css";

function Services() {
  const steps = [
    {
      title: "STEP 1",
      subtitle: "데이터 모으기",
      content: [
        {
          category: "STEP 1: 데이터 수집 및 통합 🗂️",
          describe1: "대구 지역 상권 분석의 첫 걸음은 데이터를 모으는 것입니다.",
          describe2: "신한카드와 국민카드의 매출 데이터를 대구 빅데이터 센터에서 수집합니다.",
          detailtitle:"포함 데이터:",
          details: [
            "🏢 동별 매출 정보: 각 동에서 어떤 매출이 발생했는지 확인",
            "🍴 업종별 매출 비율: 어떤 업종이 인기 있는지 한눈에 파악",
            "⏰ 시간대별 매출: 사람들이 주로 언제 소비하는지 분석",
          ],
          result1:"이 데이터들을 한곳에 모아 일관성 있게 정리합니다.",
          result2:" 대구 각 동의 상권 현황을 한눈에 볼 수 있는 기초 자료가 완성됩니다.",
        },
      ],
    },
    {
      title: "STEP 2",
      subtitle: "데이터 다듬기",
      content: [
        {
          category: "STEP 2: 데이터 정제 및 전처리 🧹",
          describe1: "수집한 데이터를 그대로 사용하기는 어렵습니다..",
          describe2: "💡 데이터를 정리하고 깔끔하게 가공하는 단계입니다.",
          detailtitle:"문제점 해결:",
          details: [
            "❌ 누락된 값: 데이터를 채워서 완전하게 만들기.",
            "⚠️ 중복 데이터: 불필요한 데이터를 제거해 분석 효율성을 높이기",
            "📊 형식 통일: 시간대, 업종, 결제 수단 등 데이터를 표준화하기",
          ],
          result2:" 🔍 동네별 매출 특성과 지역적 특징이 명확히 드러나도록 정리 완료됩니다.",
        },
      ],
    },
    {
      title: "STEP 3",
      subtitle: "데이터 분석하기",
      content: [
        {
          category: "STEP 3: 데이터 분석 및 시각화 📊",
          describe1: "가공된 데이터를 활용해 사용자에게 도움을 줄 수 있는 결과를 도출합니다.",
          describe2: "차트와 그래프로 시각화 작업을 진행해 이해하기 쉬운 형태로 데이터를 전달합니다.",
          detailtitle:"분석 내용:",
          details: [
            "⏱️ 시간대별, 요일별 매출 분석: 사람들이 가장 활발히 소비하는 요일과 시간은 언제일까요?",
            "🍔 업종별 매출 비율: 매출의 주요 비중을 차지하는 업종은 무엇일까요? ",
            "📍 연령별, 성별 소비 패턴: 가장 많은 소비를 나타내는 연령과 성별은 무엇일까요?",
          ],
          result2:" 복잡한 데이터를 직관적으로 이해할 수 있도록 정리했습니다.",
        },
      ],
    },
    {
      title: "STEP 4",
      subtitle: "WEBSITE에 담기",
      content: [
        {
          category: "STEP 4: 웹사이트 통합 및 사용자 인터페이스 구성 💻",
          describe1: "마지막으로, 분석 결과를 실제 사용자들에게 전달합니다!",
          describe2: "🎨 직관적인 디자인으로 푸드트럭 창업자들이 쉽게 사용할 수 있습니다.",
          detailtitle:"📌 핵심 기능:",
          details: [
            "🗺️ 동별 매출 현황을 한눈에 볼 수 있는 대시보드.",
            "📈  매출 TOP10과 매출 상승률 TOP10 차트로 트렌드 확인.",
            "⏰ 매출이 높은 시간대와 인기가 많은 업종 추천.",
          ],
          result2:" 푸드트럭 창업자가 효율적으로 데이터를 활용해 최고의 입지를 찾을 수 있습니다.",
        },
      ],
    },
  ];

  const [activeStep, setActiveStep] = useState(0);

  return (
    <div className="services-section">
      <h4>Data</h4>
      <h2>상권 분석 정보 데이터<br></br> 가공 과정</h2>
      <p>아래 소개된 4단계의 과정을 거쳐 FoodTruckFinder의<br></br> 상권분석 데이터가 만들어지고 있습니다.</p>
    <div className="services-container">
      <div className="timeline">
        {steps.map((step, index) => (
          <div
            key={index}
            className={`timeline-step ${
              activeStep === index ? "active" : ""
            }`}
            onClick={() => setActiveStep(index)}
          >
            <h4>{step.title}</h4>
            <p><b>{step.subtitle}</b></p>
          </div>
        ))}
      </div>
      <div className="step-content">
        {/* <h3>{steps[activeStep].subtitle}</h3> */}
        {steps[activeStep].content.map((section, index) => (
          <div key={index} className="content-section">
            <br></br>
            <h4>{section.category}</h4>
            <h5>{section.describe1}</h5>
            <h5>{section.describe2}</h5>
            
            <h3>{section.detailtitle}</h3>
            <ul>
              {section.details.map((item, idx) => (
                <li key={idx}>{item}</li>
              ))}
            </ul>
           
            <h5>{section.result1}</h5>
            <br></br>
            <h5>👉 결과:</h5>
            <h6>{section.result2}</h6>
          </div>
        ))}
      </div>
    </div>
    </div>
  );
}

export default Services;
