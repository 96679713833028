import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import RegulationBoard from "../components/regulation/RegulationBoard";
import styled from "styled-components";
import Footer from "../components/Footer";
import LeftBar from "../components/LeftBar";

// import RecruitmentFilter from '../components/Recruitment/RecruitmentFilter';
import regulationMockData from "../components/regulation/RegulationMockData";
import RegulationFilter from "../components/regulation/RegulationFilter";

// Styled components
const Container = styled.div`
  display: flex;
  width: 100%;
  height: 90vh;
  flex-direction: column;
`;
const FullContainer = styled.div`
  display: flex;
  width: 100%;
  height: 90vh;
`;
const BoardSection = styled.div`
  flex: 5;
  padding: 1rem;
`;

function RegulationList() {
  const [selectedCategory, setSelectedCategory] = useState(""); // 선택된 카테고리
  const [selectedRegion, setSelectedRegion] = useState(""); // 선택된 지역
  const [regulations, setRegulations] = useState([]); // 규제 데이터 상태
  const [isLoading, setIsLoading] = useState(true); // 로딩 상태

  // API 호출을 통해 규제 데이터 가져오기
  useEffect(() => {
    const fetchData = async () => {
      try {
        // const response = await getRegulationList();
        // setRegulations(response.regulations); // 데이터 상태 업데이트
        setRegulations(regulationMockData);
      } catch (error) {
        console.error("Error fetching regulation list:", error);
      } finally {
        setIsLoading(false); // 로딩 완료 후 상태 업데이트
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Navbar />
      <FullContainer>
        <LeftBar />
        <Container>
          {/* Filter Section */}
          <RegulationFilter
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            selectedRegion={selectedRegion}
            setSelectedRegion={setSelectedRegion}
          />

          {/* Regulation Board Section */}
          <BoardSection>
            {isLoading ? (
              <p>Loading...</p> // 로딩 중일 때 표시
            ) : (
              <RegulationBoard regulations={regulations} />
            )}
          </BoardSection>
        </Container>
      </FullContainer>
      <hr />
      <Footer />
    </>
  );
}

export default RegulationList;
