import React, { useState } from "react";

const growthData = [
  { rank: 1, region: "산격 3동", amount: "0.07억", growth: "91.15%", isUp: true },
  { rank: 2, region: "파동", amount: "0.64억", growth: "18.13%", isUp: true },
  { rank: 3, region: "성내 3동", amount: "0.10억", growth: "7.6%", isUp: true },
  { rank: 4, region: "신천 4동", amount: "14.86억", growth: "-19.27%", isUp: false },
  { rank: 5, region: "대신동", amount: "34.94억", growth: "-20.7%", isUp: false },
  { rank: 6, region: "가창면", amount: "30.14억", growth: "-22.62%", isUp: false },
  { rank: 7, region: "고산 2동", amount: "29.27억", growth: "-22.82%", isUp: false },
  { rank: 8, region: "해안동", amount: "3.43억", growth: "-23.14%", isUp: false },
  { rank: 9, region: "범어4동", amount: "132.06억", growth: "-26.06%", isUp: false },
  { rank: 10, region: "내당 2.3동", amount: "40.05억", growth: "-27.14%", isUp: false },
];

const salesData = [
  { rank: 1, region: "두산동", amount: "515.18억", growth: "-29.95%", isUp: false },
  { rank: 2, region: "성내 1동", amount: "400.18억", growth: "-33.07%", isUp: false },
  { rank: 3, region: "성내 2동", amount: "308.78억", growth: "-28.46%", isUp: false },
  { rank: 4, region: "삼덕동", amount: "173.24억", growth: "-38.44%", isUp: false },
  { rank: 5, region: "유가읍", amount: "159.12억", growth: "-29.83%", isUp: false },
  { rank: 6, region: "효목 1동", amount: "143.23억", growth: "-31.34%", isUp: false },
  { rank: 7, region: "현풍읍", amount: "142.75억", growth: "-39.43%", isUp: false },
  { rank: 8, region: "화원읍", amount: "139.02억", growth: "-39.19%", isUp: false },
  { rank: 9, region: "상동", amount: "133.47억", growth: "-46.05%", isUp: false },
  { rank: 10, region: "범어 4동", amount: "132.06억", growth: "-26.06%", isUp: false },
];

const styles = {
  container: {
    width: "400px",
    padding: "1rem",
    backgroundColor: "#f9f9f9",
    border: "1px solid #ddd",
    borderRadius: "8px",
    fontFamily: "Arial, sans-serif",
  },
  tabContainer: {
    display: "flex",
    justifyContent: "space-around",
    marginBottom: "1rem",
    borderBottom: "2px solid #ddd",
  },
  tab: {
    padding: "0.5rem 1rem",
    cursor: "pointer",
    fontSize: "1rem",
    fontWeight: "bold",
  },
  activeTab: {
    color: "#d46618",
    borderBottom: "2px solid #d46618",
  },
  chartContainer: {
    marginTop: "1rem",
  },
  list: {
    listStyle: "none",
    padding: 0,
    margin: 0,
  },
  listItem: {
    marginBottom: "1rem",
    padding: "0.5rem",
    backgroundColor: "#fff",
    borderRadius: "6px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  },
  topRow: {
    display: "flex",
    alignItems: "center",
    marginBottom: "0.3rem",
  },
  rank: {
    fontSize: "1.2rem",
    fontWeight: "bold",
    color: "#d46618",
    marginRight: "0.8rem",
  },
  region: {
    fontSize: "1rem",
    fontWeight: "bold",
    color: "#333",
  },
  bottomRow: {
    display: "flex",
    justifyContent: "space-between",
  },
  amount: {
    marginLeft: "1.5rem",
    fontSize: "0.9rem",
    color: "#666",
  },
  growth: {
    fontSize: "0.9rem",
    fontWeight: "bold",
  },
  growthUp: {
    color: "#e74c3c", // 상승 색상
  },
  growthDown: {
    color: "#3498db", // 하락 색상
  },
};

const Chart = ({ data }) => (
  <ul style={styles.list}>
    {data.map((item) => (
      <li key={item.rank} style={styles.listItem}>
        <div style={styles.topRow}>
          <div style={styles.rank}>{item.rank}</div>
          <div style={styles.region}>{item.region}</div>
        </div>
        <div style={styles.bottomRow}>
          <span style={styles.amount}>{item.amount}</span>
          <span
            style={{
              ...styles.growth,
              ...(item.isUp ? styles.growthUp : styles.growthDown),
            }}
          >
            {item.growth} {item.isUp ? "↑" : "↓"}
          </span>
        </div>
      </li>
    ))}
  </ul>
);

const LeftBar = () => {
  const [activeTab, setActiveTab] = useState("sales"); // 초기 탭 설정

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div style={styles.container}>
      {/* 탭 영역 */}
      <div style={styles.tabContainer}>
        <div
          style={{
            ...styles.tab,
            ...(activeTab === "sales" ? styles.activeTab : {}),
          }}
          onClick={() => handleTabClick("sales")}
        >
          매출 TOP 10
        </div>
        <div
          style={{
            ...styles.tab,
            ...(activeTab === "growth" ? styles.activeTab : {}),
          }}
          onClick={() => handleTabClick("growth")}
        >
          매출상승률 TOP 10
        </div>
      </div>

      {/* 차트 표시 */}
      <div style={styles.chartContainer}>
        {activeTab === "sales" && <Chart data={salesData} />}
        {activeTab === "growth" && <Chart data={growthData} />}
      </div>
    </div>
  );
};

export default LeftBar;
