const regulationMockDataList = [
  {
    id: 1,
    title: "영업신고",
    category: "필수",
    url: "https://www.gov.kr/mw/AA020InfoCappView.do?HighCtgCD=A09006&CappBizCD=14600000021&tp_seq=10",
    description: "영업신고 시 참고하시기 바랍니다.",
    region: "전체",
    startDate: "2024-01-01",
    endDate: "2024-12-31",
    status: "진행 중",
  },
  {
    id: 2,
    title: "자동차 구조 장치 변경 승인",
    category: "필수",
    url: "https://m.gov.kr/mw/AA020InfoCappView.do?HighCtgCD=A03007&CappBizCD=15000000353&tp_seq=",
    description: "자동차 구조 장치 변경 승인 관련 정보입니다.",
    region: "전체",
    startDate: "2024-01-01",
    endDate: "2024-12-31",
    status: "진행 중",
  },
  {
    id: 3,
    title: "완성(정기)검사신청(액화석유가스관련 시설)",
    category: "필수",
    url: "https://www.gov.kr/mw/AA020InfoCappView.do?HighCtgCD=A03006&CappBizCD=14100000098&tp_seq=01",
    description: "액화석유가스 관련 시설 검사 신청 참고용입니다.",
    region: "전체",
    startDate: "2024-01-01",
    endDate: "2024-12-31",
    status: "진행 중",
  },
  {
    id: 4,
    title: "온라인 위생 교육",
    category: "필수",
    url: "https://www.kfia21.or.kr/",
    description: "온라인 위생 교육 정보입니다.",
    region: "전체",
    startDate: "2024-01-01",
    endDate: "2024-12-31",
    status: "진행 중",
  },
  {
    id: 5,
    title: "건강진단결과서(보건증) 발급",
    category: "필수",
    url: "https://www.gov.kr/portal/service/serviceInfo/135200000129",
    description: "보건증 발급 관련 정보입니다.",
    region: "전체",
    startDate: "2024-01-01",
    endDate: "2024-12-31",
    status: "진행 중",
  },
  {
    id: 6,
    title: "동구청",
    category: "참고",
    url: "https://www.dong.daegu.kr/main.do",
    description: "동구청 홈페이지입니다.",
    region: "동구",
    startDate: "2024-01-01",
    endDate: "2024-12-31",
    status: "참고",
  },
  {
    id: 7,
    title: "서구청",
    category: "참고",
    url: "https://www.dgs.go.kr/dgs/",
    description: "서구청 홈페이지입니다.",
    region: "서구",
    startDate: "2024-01-01",
    endDate: "2024-12-31",
    status: "참고",
  },
  // {
  //   id: 8,
  //   title: "화물자동차 운수사업법",
  //   category: "법령",
  //   url: "https://www.law.go.kr/lsSc.do?menuId=1&subMenuId=15&query=%ED%99%94%EB%AC%BC%EC%9E%90%EB%8F%99%EC%B0%A8%20%EC%9A%B4%EC%88%98%EC%82%AC%EC%97%85%EB%B2%95&dt=20201211#undefined",
  //   description: "화물자동차 운수사업법 관련 법령입니다.",
  //   region: "전체",
  //   startDate: "2024-01-01",
  //   endDate: "2024-12-31",
  //   status: "참고",
  // },
  {
    id: 8,
    title: "푸드트럭 창업지원",
    category: "참고",
    url: "https://www.gov.kr/portal/rcvfvrSvc/dtlEx/B50000400011?administOrgCd=",
    description: "푸드트럭 창업지원 정보입니다.",
    region: "전체",
    startDate: "2024-01-01",
    endDate: "2024-12-31",
    status: "진행 중",
  },
];

export default regulationMockDataList;
