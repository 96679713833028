import axios from "axios";

export const apiClient = axios.create({
  //   baseURL: "http://localhost:8080/api/v1",
  baseURL: "https://4957-58-126-218-174.ngrok-free.app/api/v1",
  headers: {
    "Content-type": "application/json",
    "ngrok-skip-browser-warning": true,
  },
});
