// import React, { useState } from 'react';
// import styled from 'styled-components';

// // Styled components
// const FilterContainer = styled.div`
//     flex: 1;
//     padding: 1rem;
//     background-color: #f0f0f0;
//     border-right: 1px solid #ddd;
// `;

// const FilterTitle = styled.h3`
//     font-size: 1.4rem;
//     color: #333;
// `;

// const CheckboxContainer = styled.div`
//     margin: 1rem 0;
// `;

// const DropdownContainer = styled.div`
//     margin: 1rem 0;
// `;

// const FilterCheckbox = styled.input`
//     margin-right: 0.5rem;
// `;

// const FilterLabel = styled.label`
//     font-size: 1rem;
//     color: #333;
// `;

// const FilterSelect = styled.select`
//     padding: 0.5rem;
//     width: 100%;
//     border: 1px solid #ddd;
//     border-radius: 4px;
// `;

// const SearchContainer = styled.div`
//     margin-top: 1.5rem;
//     text-align: right;
// `;

// const SearchInput = styled.input`
//     padding: 0.5rem;
//     width: 200px;
//     border: 1px solid #ddd;
//     border-radius: 4px;
//     margin-right: 0.5rem;
// `;

// const RecruitmentFilter = ({ isOngoing, setIsOngoing, selectedRegion, setSelectedRegion }) => {
//     // 지역 옵션 (대구의 구)
//     const regions = ['북구', '동구', '서구', '남구', '중구', '수성구', '달서구', '달성군'];

//     return (
//         <FilterContainer>
//             <FilterTitle>게시물 필터링</FilterTitle>

//             {/* 공모 진행 여부 체크 */}
//             <CheckboxContainer>
//                 <FilterCheckbox
//                     type="checkbox"
//                     checked={isOngoing}
//                     onChange={(e) => setIsOngoing(e.target.checked)}
//                 />
//                 <FilterLabel>공모 진행중</FilterLabel>
//             </CheckboxContainer>

//             {/* 지역 체크 */}
//             <DropdownContainer>
//                 <FilterLabel>지역 선택</FilterLabel>
//                 <FilterSelect
//                     value={selectedRegion}
//                     onChange={(e) => setSelectedRegion(e.target.value)}
//                 >
//                     <option value="">전체</option>
//                     {regions.map((region) => (
//                         <option key={region} value={region}>{region}</option>
//                     ))}
//                 </FilterSelect>
//             </DropdownContainer>
//             <SearchContainer>
//                 <SearchInput type="text" placeholder="검색어를 입력하세요" />
//                 <button>검색</button>
//             </SearchContainer>

//         </FilterContainer>
//     );
// };
//동환님 코드
// export default RecruitmentFilter;
// import React, { useState } from 'react';
import styled from "styled-components";

// Styled components
const FilterContainer = styled.div`
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 220px;
  padding: 1rem;
`;
const FilterTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 750px;
  margin-bottom: 1rem;
`;

const DropdownButton = styled.select`
  padding: 0.5rem 1rem;
  border: 2px solid #d46618;
  border-radius: 30px;
  background-color: white;
  color: #d46618;
  font-size: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  outline: none;
  cursor: pointer;
`;

const SearchInput = styled.input`
  padding: 0.5rem 1rem 0.5rem 1rem;
  width: 500px;
  border: 2px solid #d46618;
  border-radius: 30px;
  margin-right: 1rem;
  font-size: 1rem;
  outline: none;
  color: #333;
`;

const SearchButton = styled.button`
  background-color: #d46618;
  border: none;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 50%;
  cursor: pointer;
  font-size: 1rem;
  margin-right: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ResetButton = styled.button`
  background-color: #d46618;
  border: none;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 50%;
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FilterTitle = styled.h3`
  font-size: 1.7rem;
  color: #333;
  text-align: center;
  margin-bottom: 2rem;
  margin-top: 1rem;
`;

// const CheckboxContainer = styled.div`
//     display: flex;
//     align-items: center;
// `;

// const FilterCheckbox = styled.input`
//     margin-right: 0.5rem;
// `;

// const FilterLabel = styled.label`
//     font-size: 1rem;
//     color: #333;
// `;

const FilterButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  max-width: 750px;
  margin-top: 0.5rem;
  gap: 0.5rem; /* 버튼 간격 */
`;

const ActionButton = styled.button`
  padding: 0.3rem 1rem;
  border: 2px solid #d46618;
  border-radius: 30px;
  background-color: white;
  color: #d46618;
  font-size: 1rem;
  cursor: pointer;
  outline: none;
`;

const SmallDropdown = styled.select`
  padding: 0.4rem 1rem;
  border: 2px solid #d46618;
  border-radius: 30px;
  background-color: white;
  color: #d46618;
  font-size: 1rem;
  cursor: pointer;
  outline: none;
`;

const RecruitmentFilter = ({ selectedRegion, setSelectedRegion }) => {
  // 지역 옵션 (대구의 구)
  const regions = [
    "북구",
    "동구",
    "서구",
    "남구",
    "중구",
    "수성구",
    "달서구",
    "달성군",
  ];

  return (
    <FilterContainer>
      <FilterTitle>푸드트럭 공모</FilterTitle>

      {/* 공모 진행 여부 체크 */}
      {/* <CheckboxContainer>
                <FilterCheckbox
                    type="checkbox"
                    checked={isOngoing}
                    onChange={(e) => setIsOngoing(e.target.checked)}
                />
                <FilterLabel>공모 진행중</FilterLabel>
            </CheckboxContainer> */}

      <FilterTop>
        {/* 드롭다운 버튼 */}
        <DropdownButton
          value={selectedRegion}
          onChange={(e) => setSelectedRegion(e.target.value)}
        >
          <option value="">관할구명</option>
          {regions.map((region) => (
            <option key={region} value={region}>
              {region}
            </option>
          ))}
        </DropdownButton>

        {/* 검색어 입력 */}
        <SearchInput type="text" placeholder="검색어를 입력해 주세요." />

        {/* 검색 버튼 */}
        <SearchButton>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            width="24"
            fill="white"
          >
            <path d="M9.5 2a7.5 7.5 0 105.53 12.6l5.88 5.88a1 1 0 001.42-1.42l-5.88-5.88A7.5 7.5 0 009.5 2zm0 2a5.5 5.5 0 110 11 5.5 5.5 0 010-11z" />
          </svg>
        </SearchButton>

        {/* 리셋 버튼 */}
        <ResetButton>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            width="24"
            fill="white"
          >
            <path d="M13 3a9 9 0 000 18v-2a7 7 0 110-14 7 7 0 010 14h1.5v2H13A9 9 0 0013 3z" />
          </svg>
        </ResetButton>
      </FilterTop>
      <FilterButtonGroup>
        <ActionButton>전체</ActionButton>
        <ActionButton>공모진행중</ActionButton>
        <SmallDropdown>
          <option value="option1">최신순</option>
          <option value="option2">사전순</option>
          <option value="option3">인기순</option>
        </SmallDropdown>
      </FilterButtonGroup>
    </FilterContainer>
  );
};

export default RecruitmentFilter;
