import { apiClient } from "./apiClient";

export const getTouristAttractions = async (mapX, mapY) => {
  try {
    const res = await apiClient.get(`/tour/list?mapX=${mapX}&mapY=${mapY}`);
    console.log(
      "res.data.response.body.items.item",
      res.data.response.body.items.item
    );
    return res.data.response.body.items.item;
  } catch (error) {
    console.error("Error fetching tourist attractions:", error);
    throw error;
  }
};
