import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  getSalesByBusinessCode,
  getSalesBySexAge,
  getSalesByDayOfWeek,
  getSalesByTime,
  getSalesByMonth,
} from "../../api/commercial/commercialApi";

const SummaryContainer = styled.div`
  background: #ffffff;
  padding: 20px;
  font-family: Arial, sans-serif;
  font-size: 1.2rem;
  line-height: 1.6;
  color: #333333;
`;

const Highlight = styled.span`
  color: #d9601a;
  font-weight: bold;
`;

const HighlightBlackBold = styled.span`
  color: black;
  font-weight: bold;
`;

// Helper function to find max value
const findMax = (data, key) => {
  return data.reduce(
    (max, item) => (item[key] > max[key] ? item : max),
    data[0]
  );
};

// Helper function for gender-age analysis
const findMaxAgeGroup = (ageGroupSales) => {
  return Object.entries(ageGroupSales).reduce(
    (max, [key, value]) => (value > max.value ? { key, value } : max),
    { key: null, value: 0 }
  );
};

const AnalysisSummary = ({ district }) => {
  const [analysisResults, setAnalysisResults] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [businessData, sexAgeData, dayData, timeData, monthData] =
          await Promise.all([
            getSalesByBusinessCode(district),
            getSalesBySexAge(district),
            getSalesByDayOfWeek(district),
            getSalesByTime(district),
            getSalesByMonth(district),
          ]);

        const maxBusiness = findMax(
          businessData.salesByBusinessCode,
          "salesAmount"
        );
        const maxFemaleAge = findMaxAgeGroup(sexAgeData[0].ageGroupSales);
        const maxMaleAge = findMaxAgeGroup(sexAgeData[1].ageGroupSales);
        const maxDay = findMax(dayData, "salesAmount");
        const maxTime = findMax(timeData, "salesAmount");
        const maxMonthCafe = findMax(
          monthData.filter((item) => item.category === "카페"),
          "salesAmount"
        );
        const maxMonthRestaurant = findMax(
          monthData.filter((item) => item.category === "음식점"),
          "salesAmount"
        );

        setAnalysisResults({
          businessAnalysis: maxBusiness.businessCode,
          genderAgeAnalysis: {
            femaleAge: maxFemaleAge.key,
            maleAge: maxMaleAge.key,
          },
          dayAnalysis: maxDay.dayOfWeek,
          timeAnalysis: maxTime.timeSlot,
          monthlyAnalysis: {
            cafeMonth: maxMonthCafe.month,
            restaurantMonth: maxMonthRestaurant.month,
          },
        });
      } catch (error) {
        console.error("Error fetching analysis data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [district]);

  if (loading) return <p>Loading analysis...</p>;
  if (!analysisResults) return <p>No analysis data available</p>;

  return (
    <SummaryContainer>
      <h3>
        <Highlight>
          {" "}
          #{district} #{analysisResults.timeAnalysis} #
          {analysisResults.dayAnalysis}요일 #{analysisResults.businessAnalysis}{" "}
          #여성{analysisResults.genderAgeAnalysis.femaleAge} #남성
          {analysisResults.genderAgeAnalysis.maleAge}
        </Highlight>
      </h3>
      <p>
        <HighlightBlackBold>{district}</HighlightBlackBold>의 매출이 가장 높은
        시간대는{" "}
        <HighlightBlackBold>{analysisResults.timeAnalysis}</HighlightBlackBold>,{" "}
        <HighlightBlackBold>
          {analysisResults.dayAnalysis}요일
        </HighlightBlackBold>
        이며,{" "}
        <HighlightBlackBold>
          {analysisResults.businessAnalysis}
        </HighlightBlackBold>{" "}
        업종이 매출의 주요 비중을 차지합니다. 이 지역에서 가장 많은 소비를 하는
        연령대는{" "}
        <HighlightBlackBold>
          {analysisResults.genderAgeAnalysis.femaleAge}
        </HighlightBlackBold>{" "}
        (여성)와{" "}
        <HighlightBlackBold>
          {analysisResults.genderAgeAnalysis.maleAge}
        </HighlightBlackBold>{" "}
        (남성)이며 주요 소비 시간대는{" "}
        <HighlightBlackBold>{analysisResults.timeAnalysis}</HighlightBlackBold>
        입니다.
      </p>
      <p>
        Finder의 분석에 따르면, <HighlightBlackBold>주말</HighlightBlackBold>과{" "}
        <HighlightBlackBold>저녁 시간대</HighlightBlackBold>에 방문객이 많아
        푸드트럭 창업에 적합한 지역으로 예상됩니다. Finder의 추천으로는{" "}
        <HighlightBlackBold>화요일 오후 6시~8시</HighlightBlackBold>에{" "}
        <HighlightBlackBold>한식 전문점</HighlightBlackBold>과 같은 메뉴를
        제공하여 높은 매출을 기대할 수 있습니다. 또한,{" "}
        <HighlightBlackBold>평일 오후 시간대</HighlightBlackBold>를 활용한
        이벤트를 계획하면 매출 증가 효과를 기대할 수 있습니다.
      </p>
    </SummaryContainer>
  );
};

export default AnalysisSummary;
