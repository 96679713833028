// import React, { useState } from 'react';
// import styled from 'styled-components';

// // Styled components
// const FilterContainer = styled.div`
//     // flex: 1;
//     padding: 1rem;
//     display:flex;
//     flex-direction:column;
//     align-items:center;
//     height:220px;
//     background-color: #f0f0f0;
//     // border-right: 1px solid #ddd;
// `;

// const FilterTitle = styled.h3`
//     font-size: 1.4rem;
//     color: #333;
// `;

// const CheckboxContainer = styled.div`
//     margin: 1rem 0;
// `;

// const DropdownContainer = styled.div`
//     margin: 1rem 0;
// `;

// const FilterCheckbox = styled.input`
//     margin-right: 0.5rem;
// `;

// const FilterLabel = styled.label`
//     font-size: 1rem;
//     color: #333;
// `;

// const FilterSelect = styled.select`
//     padding: 0.5rem;
//     width: 100%;
//     border: 1px solid #ddd;
//     border-radius: 4px;
// `;

// const SearchContainer = styled.div`
//     margin-top: 1.5rem;
//     text-align: right;
// `;

// const SearchInput = styled.input`
//     padding: 0.5rem;
//     width: 200px;
//     border: 1px solid #ddd;
//     border-radius: 4px;
//     margin-right: 0.5rem;
// `;

// const RegulationFilter = ({
//     isActive,
//     setIsActive,
//     selectedCategory,
//     setSelectedCategory,
//     selectedRegion,
//     setSelectedRegion,
//     searchTerm,
//     setSearchTerm
// }) => {
//     // 규제 카테고리 예시
//     const categories = ['푸드트럭 영업', '상업시설', '환경 보호', '교통 규제'];
//     // 지역 옵션 (대구의 구)
//     const regions = ['북구', '동구', '서구', '남구', '중구', '수성구', '달서구', '달성군'];

//     return (
//         <FilterContainer>
//             <FilterTitle>규제 필터링</FilterTitle>
            
//             {/* 규제 활성 여부 체크 */}
//             <CheckboxContainer>
//                 <FilterCheckbox
//                     type="checkbox"
//                     checked={isActive}
//                     onChange={(e) => setIsActive(e.target.checked)}
//                 />
//                 <FilterLabel>활성 규제</FilterLabel>
//             </CheckboxContainer>

//             {/* 카테고리 선택 */}
//             <DropdownContainer>
//                 <FilterLabel>카테고리 선택</FilterLabel>
//                 <FilterSelect
//                     value={selectedCategory}
//                     onChange={(e) => setSelectedCategory(e.target.value)}
//                 >
//                     <option value="">전체</option>
//                     {categories.map((category) => (
//                         <option key={category} value={category}>{category}</option>
//                     ))}
//                 </FilterSelect>
//             </DropdownContainer>

//             {/* 지역 선택 */}
//             <DropdownContainer>
//                 <FilterLabel>지역 선택</FilterLabel>
//                 <FilterSelect
//                     value={selectedRegion}
//                     onChange={(e) => setSelectedRegion(e.target.value)}
//                 >
//                     <option value="">전체</option>
//                     {regions.map((region) => (
//                         <option key={region} value={region}>{region}</option>
//                     ))}
//                 </FilterSelect>
//             </DropdownContainer>

//             {/* 검색창 */}
//             <SearchContainer>
//                 <SearchInput 
//                     type="text" 
//                     placeholder="검색어를 입력하세요"
//                     value={searchTerm}
//                     onChange={(e) => setSearchTerm(e.target.value)}
//                 />
//                 <button>검색</button>
//             </SearchContainer>
//         </FilterContainer>
//     );
// };

// export default RegulationFilter;
import React, { useState } from "react";
import styled from "styled-components";

// Styled components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f4f4f4;
  padding: 2rem 1rem;
`;

const Title = styled.h1`
  font-size: 1.7rem;
  color: #333;
  margin-bottom: 2rem;
  
`;

const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  max-width: 750px;
  margin-bottom: 1rem;
`;

const Dropdown = styled.select`
  padding: 0.5rem;
  border: 2px solid #d46618;
  border-radius: 4px;
  background-color: #fff;
  color: #d46618;
  font-size: 1rem;
  outline: none;

  option {
    color: #333;
  }
`;

const SearchInput = styled.input`
  flex: 1;
  padding: 0.5rem;
  width: 500px;
  border-radius: 30px;
  border: 2px solid #d46618;
  border-radius: 4px;
  font-size: 1rem;
`;

const SearchButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: #d46618;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;

`;

const FilterButtonsContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  width:100%;
  max-width:750px; 
  margin-top:0.5rem;
`;

const FilterButton = styled.button`
  padding: 0.5rem 1rem;
  border: 2px solid #d46618;
  border-radius: 20px;
  background-color: #fff;
  color: #d46618;
  font-size: 0.9rem;
  cursor: pointer;

  &:hover {
    background-color: #d46618;
    color: #fff;
  }

  &.active {
    background-color: #fff;
    color: #d46618;
  }
`;
const SmallDropdown = styled.select`
    padding: 0.5rem 1rem;
    border: 2px solid #d46618;
    border-radius: 20px;
    background-color: white;
    color: #d46618;
    font-size: 0.9rem;
    cursor: pointer;
    outline: none;
`;

// Component
const RegulationFilter = () => {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [activeFilter, setActiveFilter] = useState("전체");

  const categories = ["관할 구명", "영업 조건", "기타"];
  const regions = ["전체", "공모 진행중", "취소된"];

  return (
    <Container>
      <Title>규제 필터링</Title>
      <SearchBarContainer>
        <Dropdown
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
        >
          {categories.map((category, idx) => (
            <option key={idx} value={category}>
              {category}
            </option>
          ))}
        </Dropdown>
        <Dropdown
          value={selectedRegion}
          onChange={(e) => setSelectedRegion(e.target.value)}
        >
          {regions.map((region, idx) => (
            <option key={idx} value={region}>
              {region}
            </option>
          ))}
        </Dropdown>
        <SearchInput
          type="text"
          placeholder="검색어를 입력해 주세요."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <SearchButton>
          <i className="fas fa-search"></i> 검색
        </SearchButton>
      </SearchBarContainer>
      <FilterButtonsContainer>
        {regions.map((region, idx) => (
          <FilterButton
            key={idx}
            className={activeFilter === region ? "active" : ""}
            onClick={() => setActiveFilter(region)}
          >
            {region}
          </FilterButton>
        ))}
        <SmallDropdown>
                     <option value="option1">최신순</option>
                     <option value="option2">사전순</option>
                     <option value="option3">인기순</option>
        </SmallDropdown>
      </FilterButtonsContainer>
    </Container>
  );
};

export default RegulationFilter;
