export const districtDescriptions = [
  {
    name: "성내2동",
    description:
      "성내2동은 대구 중구에 위치한 행정동으로, 대구의 전통과 현대가 공존하는 중심지입니다. 대구백화점과 동성로가 인근에 있어 대구 시민들의 주요 쇼핑 및 문화 중심지로 자리 잡고 있습니다. 특히, 다양한 맛집과 카페가 밀집해 있어 젊은이들과 관광객들에게 사랑받는 곳입니다.",
  },
  {
    name: "해안동",
    description:
      "해안동은 대구 중구에 위치한 조용한 행정동으로, 대구역과 가까워 교통이 편리한 장점이 있습니다. 인근에 전통시장과 골목 상권이 어우러져 생활 편의성을 제공하며, 대구 근대 골목 투어를 즐길 수 있는 역사적인 지역 중 하나입니다.",
  },
  {
    name: "대신동",
    description:
      "대신동은 대구 중구에 위치한 행정동으로, 서문시장과 가까운 거리에 있어 전통 시장의 활기찬 분위기를 느낄 수 있는 곳입니다. 특히, 서문야시장이 열리는 장소로도 유명하며, 대구 시민들의 문화와 상업 활동이 활발하게 이루어지는 지역입니다.",
  },
  {
    name: "성내1동",
    description:
      "성내1동은 대구 중구에 위치한 행정동으로, 경상감영공원과 가까운 거리에 있어 대구의 역사적 정취를 느낄 수 있는 지역입니다. 또한, 다양한 상업시설과 전통시장이 밀집해 있어 활기찬 분위기를 자랑합니다.",
  },
  {
    name: "삼덕동",
    description:
      "삼덕동은 대구 중구에 위치한 행정동으로, 대구의 예술과 문화가 공존하는 지역입니다. 삼덕동 카페거리와 갤러리들이 있어 젊은 층과 예술가들이 모이는 장소로도 알려져 있으며, 도심 속 여유를 즐기기 좋은 곳입니다.",
  },
  {
    name: "신천4동",
    description:
      "신천4동은 대구 동구에 위치한 행정동으로, 신천 수변공원과 가까운 거리에 있어 자연과 함께하는 생활 환경을 제공합니다. 특히, 산책로나 자전거 도로가 잘 갖춰져 있어 주민들의 여가 활동이 활발히 이루어지는 지역입니다.",
  },
  {
    name: "효목1동",
    description:
      "효목1동은 대구 동구에 위치한 행정동으로, 동촌유원지와 가까운 거리에 있어 자연 속에서 여유를 즐길 수 있는 지역입니다. 또한, 주변에 교육 시설과 주거단지가 밀집해 있어 가족 단위 생활에 적합한 지역으로 알려져 있습니다.",
  },
  {
    name: "동촌동",
    description:
      "동촌동은 대구 동구에 위치한 행정동으로, 동촌유원지와 금호강이 인근에 있어 자연과 어우러진 생활을 제공합니다. 특히, 낚시와 캠핑 같은 레저 활동을 즐길 수 있는 명소로도 유명합니다.",
  },
  {
    name: "만촌3동",
    description:
      "만촌3동은 대구 수성구에 위치한 행정동으로, 대구의 주요 학군이 형성된 지역 중 하나입니다. 수성못과 가까운 거리에 있어 자연과 조화를 이루는 생활 환경을 제공하며, 학부모들에게 선호도가 높은 지역으로 알려져 있습니다.",
  },
];
