import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import RecruitmentBoard from "../components/Recruitment/RecruitmentBoard";
import RecruitmentFilter from "../components/Recruitment/RecruitmentFilter";
import styled from "styled-components";
import Footer from "../components/Footer";
import { getRecruitmentList } from "../api/recruitment/recruitmentApi";
import LeftBar from "../components/LeftBar";

// Styled components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90vh;
`;

const FullContainer = styled.div`
  display: flex;
  width: 100%;
  height: 90vh;
`;

const BoardSection = styled.div`
  flex: 5;
  padding: 1rem;
`;

function RecruitmentList() {
  const [isOngoing, setIsOngoing] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState("");
  const [recruitments, setRecruitments] = useState([]); // 모집 데이터 상태
  const [isLoading, setIsLoading] = useState(true); // 로딩 상태

  // API 호출을 통해 데이터 가져오기
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getRecruitmentList();
        //deadline 역순으로 정렬
        response.recruitments.sort(function (a, b) {
          return new Date(b.deadline) - new Date(a.deadline);
        });
        setRecruitments(response.recruitments); // 데이터 상태 업데이트
      } catch (error) {
        console.error("Error fetching recruitment list:", error);
      } finally {
        setIsLoading(false); // 로딩 완료 후 상태 업데이트
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Navbar />
      <FullContainer>
        <LeftBar />
        <Container>
          {/* Filter Section */}
          <RecruitmentFilter
            isOngoing={isOngoing}
            setIsOngoing={setIsOngoing}
            selectedRegion={selectedRegion}
            setSelectedRegion={setSelectedRegion}
          />

          {/* Recruitment Board Section */}
          <BoardSection>
            {isLoading ? (
              <p>Loading...</p> // 로딩 중일 때 표시
            ) : (
              <RecruitmentBoard recruitments={recruitments} />
            )}
          </BoardSection>
        </Container>
      </FullContainer>
      <hr />
      <Footer />
    </>
  );
}

export default RecruitmentList;
