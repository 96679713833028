import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import { getSalesByDayOfWeek } from "../../api/commercial/commercialApi";

const WeeklySalesBarChart = ({ district }) => {
  const [chartData, setChartData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getSalesByDayOfWeek(district);
        const data = await response;

        // 가장 높은 매출 요일 찾기
        const maxSalesAmount = Math.max(
          ...data.map((item) => item.salesAmount)
        );
        const labels = data.map((item) => item.dayOfWeek);
        const salesAmounts = data.map((item) => item.salesAmount);

        // 색상 배열 생성 (최고 매출 요일만 진한 색)
        const backgroundColors = salesAmounts.map((amount) =>
          amount === maxSalesAmount ? "#D9601A" : "#FFA07A"
        );

        // 차트 데이터 설정
        setChartData({
          labels: labels,
          datasets: [
            {
              label: "요일별 매출",
              data: salesAmounts,
              backgroundColor: backgroundColors,
              borderColor: "#D9601A",
              borderWidth: 1,
            },
          ],
        });
      } catch (error) {
        console.error("Error fetching sales data by day of week:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [district]);

  if (isLoading) return <p>Loading...</p>;
  if (!chartData) return <p>No data available</p>;

  return (
    <Bar
      data={chartData}
      options={{
        responsive: true,
        maintainAspectRatio: false, // 부모 크기에 맞추기
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                const value = context.raw || 0;
                return `${context.label}: ${value.toLocaleString()}원`;
              },
            },
            backgroundColor: "#333333",
            titleFont: {
              size: 16,
              family: "Arial, sans-serif",
              weight: "bold",
            },
            bodyFont: {
              size: 14,
              family: "Arial, sans-serif",
            },
            borderWidth: 1,
            borderColor: "#FFFFFF",
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: "요일",
              color: "#4A4A4A",
              font: {
                size: 14,
              },
            },
            grid: {
              drawOnChartArea: false,
            },
          },
          y: {
            title: {
              display: false,
              text: "매출 금액 (원)",
              color: "#4A4A4A",
              font: {
                size: 14,
              },
            },
            ticks: {
              stepSize: 15000000000, // 격자 간격 설정 (5억 단위로 설정 예시)
              display: false,
            },
          },
        },
      }}
    />
  );
};

export default WeeklySalesBarChart;
