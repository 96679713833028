// RecruitmentContent.js
import React from "react";
import styled from "styled-components";

const ContentContainer = styled.div`
  padding: 2rem;
  background-color: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  min-height: 80vh;
  margin-bottom: 1.5rem;
`;

const Title = styled.h1`
  font-size: 2.2rem;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 1rem;
`;

const Info = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  font-size: 0.9rem;
  color: #7f8c8d;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 1rem;
  align-items: center; /* 버튼과 텍스트 정렬 */
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
`;

const Label = styled.span`
  font-weight: bold;
  color: #34495e;
  margin-right: 0.3rem;
`;

const Content = styled.div`
  font-size: 1rem;
  color: #4f4f4f;
  line-height: 1.8;
`;

const BackButton = styled.button`
  padding: 0.4rem 1rem;
  background-color: #bdc3c7;
  color: white;
  font-size: 0.9rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: auto; /* 마감일 옆으로 배치 */

  &:hover {
    background-color: #95a5a6;
  }
`;
const LinkButton = styled.a`
  padding: 0.4rem 1rem;
  background-color: #bdc3c7;
  color: white;
  font-size: 0.9rem;
  text-decoration: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 1rem;

  &:hover {
    background-color: #2980b9;
  }
`;

const IframeContainer = styled.div`
  margin-top: 2rem;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
`;

const StyledIframe = styled.iframe`
  width: 100%;
  height: 100vh;
  border: none;
`;
const RecruitmentContent = ({ recruitment }) => {
  return (
    <ContentContainer>
      <Title>{recruitment.title}</Title>
      <Info>
        <InfoItem>
          <Label>행정동:</Label> {recruitment.locationDong}
        </InfoItem>
        <InfoItem>
          <Label>지역:</Label> {recruitment.locationGu}
        </InfoItem>
        <InfoItem>
          <Label>마감일:</Label> {recruitment.deadline}
        </InfoItem>
        <LinkButton
          href={recruitment.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          원본 보러가기
        </LinkButton>
        <BackButton onClick={() => window.history.back()}>목록</BackButton>
      </Info>
      <Content>{recruitment.content}</Content>
      <IframeContainer>
        <StyledIframe
          src={recruitment.url}
          title="Regulation Details"
        ></StyledIframe>
      </IframeContainer>
    </ContentContainer>
  );
};

export default RecruitmentContent;
