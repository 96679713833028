import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const NavbarContainer = styled.nav`
  display: flex;
  align-items: center;
  background-color: #d46618;
  padding: 30px 50px;
`;

const NavLinks = styled.ul`
  list-style: none;
  display: flex;
  gap: 1rem;
  margin-left: 2rem;
`;

const NavLink = styled(Link)`
  color: white;
  text-decoration: none;

  &:hover {
    background-color: white;
    color: black;
  }
`;
const Logo = styled(Link)`
  display: flex; /* 로고 정렬을 위해 flex 사용 */
  align-items: center;
  color: white;
  text-decoration: none;
  font-size: 36px;
  font-weight: bold;
`;

const LogoImage = styled.img`
  height: 50px; /* 이미지 높이를 네비게이션 높이보다 살짝 작게 */
  margin-right: 20px; /* 로고 이미지와 텍스트 간 간격 */
`;
const Navbar = () => {
  return (
    <NavbarContainer>
      <Logo to="/">
        <LogoImage src="/LOGO.PNG" alt="TruckSiteFinder Logo" />
      </Logo>
      <NavLinks>
        <li>
          <NavLink to="/home">홈</NavLink>
        </li>
        <li>
          <NavLink to="/recruitments">최근 공모</NavLink>
        </li>
        <li>
          <NavLink to="/commercial">상권 분석 정보</NavLink>
        </li>
        <li>
          <NavLink to="/regulations">규제 정보</NavLink>
        </li>
      </NavLinks>
    </NavbarContainer>
  );
};

export default Navbar;
