import React from "react";
import styled from "styled-components";
import TimeSlotSalesBarChart from "../components/commercial/TimeSlotSalesBarChart";
import BusinessSalesPieChart from "../components/commercial/BusinessSalesPieChart";
import WeeklySalesBarChart from "../components/commercial/WeeklySalesBarChart";
import GenderAgeSalesRadarChart from "../components/commercial/GenderAgeSalesRadarChart";
import MonthlySalesLineChart from "../components/commercial/MonthlySalesLineChart";
// import Navbar from "../components/Navbar";
// import { useParams } from "react-router-dom";
import AnalysisSummary from "../components/commercial/AnalysisSummary";

const DashboardContainer = styled.div`
  display: grid;
  gap: 20px;
  padding: 20px;
  background-color: #f8f9fa;

  box-sizing: border-box;
  margin: 0 auto;
  width: 90%; /* 전체 너비의 90% */
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: ${({ columnRatios }) =>
    columnRatios || "1fr 1fr"}; /* 비율을 동적으로 설정 */
  gap: 20px;

  box-sizing: border-box;

  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* 작은 화면에서는 한 열로 배치 */
  }
`;

const Column = styled.div`
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  height: 380px;
  // overflow-y: auto;
  box-sizing: border-box;
`;

const CommercialAnalysisDashboard = ({ district }) => {
  // const { district } = useParams();

  return (
    <>
      {/* <Navbar /> */}
      <DashboardContainer>
        {/* Row 1 */}
        <Row columnRatios="2fr 1fr">
          {" "}
          {/* 첫 번째 열은 두 배 크기 */}
          <Column>
            <h3 style={{ textAlign: "center" }}>시간대별 매출</h3>
            <TimeSlotSalesBarChart district={district} />
          </Column>
          <Column>
            <h3 style={{ textAlign: "center" }}>업종별 매출</h3>
            <BusinessSalesPieChart district={district} />
          </Column>
        </Row>

        {/* Row 2 */}
        <Row columnRatios="1fr 1fr">
          {" "}
          {/* 두 열이 동일한 크기 */}
          <Column>
            <AnalysisSummary district={district} />
          </Column>
          <Column>
            <h3 style={{ textAlign: "center" }}>요일별 매출</h3>
            <WeeklySalesBarChart district={district} />
          </Column>
        </Row>

        {/* Row 3 */}
        <Row columnRatios="1fr 2fr">
          {" "}
          {/* 첫 번째 열이 세 배 크기 */}
          <Column>
            <h3 style={{ textAlign: "center" }}>성별 및 나이대별 매출</h3>
            <GenderAgeSalesRadarChart district={district} />
          </Column>
          <Column>
            <h3 style={{ textAlign: "center" }}>월별 매출</h3>
            <MonthlySalesLineChart district={district} />
          </Column>
        </Row>
      </DashboardContainer>
    </>
  );
};

export default CommercialAnalysisDashboard;
