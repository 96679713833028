import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import "chart.js/auto";
import { getSalesByBusinessCode } from "../../api/commercial/commercialApi";

const BusinessSalesPieChart = ({ district }) => {
  const [chartData, setChartData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getSalesByBusinessCode(district);
        const data = await response;

        const salesData = data.salesByBusinessCode;

        // 매출 데이터를 비율이 큰 순서로 정렬
        const sortedSalesData = [...salesData].sort(
          (a, b) => b.salesAmount - a.salesAmount
        );

        const labels = sortedSalesData.map((item) => item.businessCode);
        const salesAmounts = sortedSalesData.map((item) => item.salesAmount);

        // 색상 팔레트
        const backgroundColors = ["#D9601A", "#A64B17", "#733612", "#401E0A"];

        const borderColors = backgroundColors.map((color) => color);

        setChartData({
          labels: labels,
          datasets: [
            {
              label: "업종별 매출",
              data: salesAmounts,
              backgroundColor: backgroundColors.slice(0, salesAmounts.length),
              borderColor: borderColors.slice(0, salesAmounts.length),
              borderWidth: 2,
              hoverOffset: 10, // 호버 시 강조 효과
            },
          ],
        });
      } catch (error) {
        console.error("Error fetching sales data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [district]);

  if (isLoading) return <p>Loading...</p>;
  if (!chartData) return <p>No data available</p>;

  return (
    <Pie
      data={chartData}
      options={{
        responsive: true,
        maintainAspectRatio: false, // 부모 크기에 맞추기
        plugins: {
          legend: {
            position: "right",
            labels: {
              font: {
                size: 14,
                family: "Arial, sans-serif",
              },
              color: "#4A4A4A",
            },
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                const value = context.raw || 0;
                return `${context.label}: ${value.toLocaleString()}원`;
              },
            },
            backgroundColor: "#333333",
            titleFont: {
              size: 16,
              family: "Arial, sans-serif",
              weight: "bold",
            },
            bodyFont: {
              size: 14,
              family: "Arial, sans-serif",
            },
            borderWidth: 1,
            borderColor: "#FFFFFF",
          },
        },
        layout: {
          padding: {
            top: 20,
            bottom: 20,
          },
        },
        animation: {
          animateRotate: true,
          animateScale: true,
        },
      }}
    />
  );
};

export default BusinessSalesPieChart;
