import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

// import { getCommercialAnalysis } from "../api/commercial/commercialApi";
import Navbar from "../components/Navbar";

// import CommercialAnalysis from "../components/commercial/CommercialAnalysis";
import RegulationContent from "../components/regulation/RegulationContent";
import regulationMockDataList from "../components/regulation/RegulationMockData";

// Styled components
const Container = styled.div`
  display: flex;
  width: 80%;
  margin: 2rem auto;
  gap: 2rem;
`;

const ContentContainer = styled.div`
  flex: 3;
`;

// const AnalysisContainer = styled.div`
//   flex: 2;
//   padding: 2rem;
//   background-color: #f7f7f7;
//   box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
//   border-radius: 8px;
// `;

function RegulationDetail() {
  const { id } = useParams();
  const [regulation, setRegulation] = useState(null);
  //   const [commercialAnalysis, setCommercialAnalysis] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchRegulationData = async () => {
      try {
        // const regulationResponse = await getRegulation(id);
        const MockDataList = regulationMockDataList;
        const regulationMockData = MockDataList.find(
          (regulation) => regulation.id === parseInt(id)
        );
        setRegulation(regulationMockData);

        // const analysisResponse = await getCommercialAnalysis(regulationResponse.region);
        // setCommercialAnalysis(analysisResponse);
      } catch (error) {
        console.error(
          "Error fetching regulation data or commercial analysis:",
          error
        );
      } finally {
        setIsLoading(false);
      }
    };

    fetchRegulationData();
  }, [id]);

  if (isLoading) return <p>Loading...</p>;

  return (
    <>
      <Navbar />
      <Container>
        {/* 규제 내용 */}
        <ContentContainer>
          <RegulationContent regulation={regulation} />
        </ContentContainer>

        {/* 상권 분석 내용
                <AnalysisContainer>
                    {commercialAnalysis ? (
                        <CommercialAnalysis data={commercialAnalysis} />
                    ) : (
                        <p>상권 분석 데이터를 불러오는 중입니다...</p>
                    )}
                </AnalysisContainer> */}
      </Container>
    </>
  );
}

export default RegulationDetail;
