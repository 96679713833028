//공모 게시물 리스트 조회
import { apiClient } from "../apiClient";

export const getRecruitmentList = async () => {
  try {
    const response = await apiClient.get(`/recruitments`);
    return response.data;
  } catch (error) {
    console.error("Error fetching recruitment list:", error);
    throw error;
  }
};

export const getRecruitment = async (id) => {
  try {
    const response = await apiClient.get(`/recruitments/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching recruitment detail:", error);
    throw error;
  }
};

export const getRecruitentByDong = async (dong) => {
  try {
    const response = await apiClient.get(`/recruitments/dong/${dong}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching recruitment by dong:", error);
    throw error;
  }
};
