import React from "react";
import styled from "styled-components";

// Styled components
const Container = styled.div`
  text-align: left;
`;

const Title = styled.h3`
  margin-top: 20px;
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 20px;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const ImageCard = styled.div`
  width: 200px;
  text-align: center;
`;

const Image = styled.div`
  width: 100%;
  height: 150px;
  border-radius: 8px;
  background-color: #e0e0e0; /* 회색 배경 */
  background-image: ${(props) => (props.src ? `url(${props.src})` : "none")};
  background-size: cover;
  background-position: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Name = styled.p`
  margin-top: 10px;
  font-size: 1rem;
  font-weight: bold;
  color: #555;
`;

// React Component
const TouristAttractions = ({ attractions }) => {
  return (
    <Container>
      <Title>주변 관광지</Title>
      <ImageContainer>
        {attractions && attractions.length > 0 ? (
          attractions.map((attraction, index) => (
            <ImageCard key={index}>
              <Image src={attraction.firstimage || null} />
              <Name>{attraction.title}</Name>
            </ImageCard>
          ))
        ) : (
          <p>주변 관광지 정보가 없습니다.</p>
        )}
      </ImageContainer>
    </Container>
  );
};

export default TouristAttractions;
