import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

// Styled components
const BoardContainer = styled.div`
  width: 100%;
  margin: 1rem auto;
  padding: 1rem;
  max-width: 1200px;
`;

const BoardTitle = styled.h2`
  text-align: center;
  font-size: 1.8rem;
  color: #d46618;
  margin-bottom: 1rem;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
`;

const TableHeader = styled.th`
  padding: 0.8rem;
  background-color: #f0f0f0;
  color: #494949;
  font-weight: bold;
  border-bottom: 2px solid #474747;
  text-align: center;

  &:nth-child(3) {
    width: 40%;
  }
`;

const TableRow = styled.tr`
  border-bottom: 1px solid #ddd;
`;

const TableCell = styled.td`
  padding: 0.8rem;
  text-align: center;
  border-bottom: 1px solid #ddd;
  /* 강조 스타일 */
  ${({ isHighlighted }) =>
    isHighlighted &&
    `
        font-weight: bold;
        color: #d46618;
    `}
`;

const TitleLink = styled(Link)`
  color: #333;
  text-decoration: none;

  &:hover {
    color: #d46618;
  }
`;

const RegulationBoard = ({ regulations = [] }) => {
  return (
    <BoardContainer>
      <BoardTitle>LIST</BoardTitle>
      <Table>
        <thead>
          <tr>
            <TableHeader>No</TableHeader>
            <TableHeader>카테고리</TableHeader>
            <TableHeader>제목</TableHeader>
            <TableHeader>지역</TableHeader>
            <TableHeader>시작일</TableHeader>
            <TableHeader>종료일</TableHeader>
            <TableHeader>상태</TableHeader>
          </tr>
        </thead>
        <tbody>
          {regulations.map((regulation) => (
            <TableRow key={regulation.id}>
              <TableCell>{regulation.id}</TableCell>
              <TableCell isHighlighted={regulation.category === "필수"}>
                {regulation.category}
              </TableCell>
              <TableCell>
                <TitleLink to={`/regulations/${regulation.id}`}>
                  {regulation.title}
                </TitleLink>
              </TableCell>
              <TableCell>{regulation.region}</TableCell>
              <TableCell>{regulation.startDate}</TableCell>
              <TableCell>{regulation.endDate}</TableCell>
              <TableCell>{regulation.status}</TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </BoardContainer>
  );
};

export default RegulationBoard;
