// RecruitmentDetail.js
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { getRecruitment } from "../api/recruitment/recruitmentApi";
// import { getCommercialAnalysis } from "../api/commercial/commercialApi";
import Navbar from "../components/Navbar";
// import CommercialAnalysis from "../components/commercial/CommercialAnalysis";
import RecruitmentContent from "../components/Recruitment/RecruitmentContent";
// import CommercialAnalysisDashboard from "./CommercialAnalysisDashboard";
import MonthlySalesLineChart from "../components/commercial/MonthlySalesLineChart";
import TimeSlotSalesBarChart from "../components/commercial/TimeSlotSalesBarChart";
import WeeklySalesBarChart from "../components/commercial/WeeklySalesBarChart";
const DashboardContainer = styled.div`
  display: grid;
  gap: 20px;
  padding: 20px;
  background-color: #f8f9fa;

  box-sizing: border-box;
  margin: 0 auto;
  width: 95%; /* 전체 너비의 90% */
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: ${({ columnRatios }) =>
    columnRatios || "1fr"}; /* 비율을 동적으로 설정 */
  gap: 10px;
  margin-bottom: 20px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* 작은 화면에서는 한 열로 배치 */
  }
`;

const Column = styled.div`
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  height: 380px;
  overflow-y: hidden;
  box-sizing: border-box;
`;
// 여기까지 추가. 12.04

const Container = styled.div`
  display: flex;
  width: 90%;
  margin: 2rem auto;
  gap: 2rem;
`;

const ContentContainer = styled.div`
  flex: 3; /* 동일한 비율로 설정 */
`;

const AnalysisContainer = styled.div`
  flex: 2; /* 동일한 비율로 설정 */
  padding: 0rem;
  background-color: #f7f7f7;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

function RecruitmentDetail() {
  const { id } = useParams();
  const [recruitment, setRecruitment] = useState(null);
  // const [commercialAnalysis, setCommercialAnalysis] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchRecruitmentData = async () => {
      try {
        const recruitmentResponse = await getRecruitment(id);
        setRecruitment(recruitmentResponse);

        // const analysisResponse = await getCommercialAnalysis(
        //   recruitmentResponse.locationDong
        // );
        // setCommercialAnalysis(analysisResponse);
      } catch (error) {
        console.error(
          "Error fetching recruitment data or commercial analysis:",
          error
        );
      } finally {
        setIsLoading(false);
      }
    };

    fetchRecruitmentData();
  }, [id]);

  if (isLoading) return <p>Loading...</p>;

  return (
    <>
      <Navbar />
      <Container>
        {/* 게시물 내용 */}
        <ContentContainer>
          <RecruitmentContent recruitment={recruitment} />
        </ContentContainer>

        {/* 상권 분석 내용 */}
        <AnalysisContainer>
          {/* <CommercialAnalysisDashboard district={recruitment.locationDong} /> */}
          <DashboardContainer>
            <h2 style={{ textAlign: "center" }}>
              {recruitment.locationDong} 상권 분석
            </h2>
            <Row>
              <Column>
                <h3 style={{ textAlign: "center" }}>시간대별 매출</h3>

                <TimeSlotSalesBarChart district={recruitment.locationDong} />
              </Column>
            </Row>

            <Row>
              <Column>
                <h3 style={{ textAlign: "center" }}>요일별 매출</h3>
                <WeeklySalesBarChart district={recruitment.locationDong} />
              </Column>
            </Row>

            <Row>
              <Column>
                <h3 style={{ textAlign: "center" }}>월별 매출</h3>
                <MonthlySalesLineChart district={recruitment.locationDong} />
              </Column>
            </Row>
          </DashboardContainer>
        </AnalysisContainer>
      </Container>
    </>
  );
}

export default RecruitmentDetail;
