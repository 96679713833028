import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

// Styled components
const BoardContainer = styled.div`
    width: 100%;
    margin: 1rem auto;
    padding: 1rem;
    max-width:1200px;
 `;

const BoardTitle = styled.h2`
    text-align: center;
    font-size: 1.8rem;
    color: #d46618;
    margin-bottom:1rem;
`;

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin-top: 1rem;
`;

const TableHeader = styled.th`
    padding: 0.8rem;
    background-color: #f0f0f0;
    color: #494949;
    font-weight: bold;
    border-bottom: 2px solid #474747;
    text-align: center;

    &:nth-child(3) {
        width: 50%; /* 제목 열의 폭을 넓게 설정 */
    }
`;

const TableRow = styled.tr`
    border-bottom: 1px solid #ddd; /* 가로 구분선만 추가 */
`;

const TableCell = styled.td`
    padding: 0.8rem;
    text-align: center;
    border-bottom: 1px solid #ddd; /* 가로 구분선만 추가 */
`;

const TitleLink = styled(Link)`
    color: #333;
    text-decoration: none;

    &:hover {
        color: #007bff; /* 링크에 호버 효과 */
    }
`;

const RecruitmentBoard = ({recruitments=[]}) => {
    return (
        <BoardContainer>
            <BoardTitle>LIST</BoardTitle>
            <Table>
                <thead>
                    <tr>
                        <TableHeader>No</TableHeader>
                        <TableHeader>행정동</TableHeader>
                        <TableHeader>제목</TableHeader>
                        <TableHeader>마감일</TableHeader>
                    </tr>
                </thead>
                <tbody>
                    {recruitments.map((item) => (
                        <TableRow key={item.id}>
                            <TableCell>{item.id}</TableCell>
                            <TableCell>{item.locationDong}</TableCell>
                            <TableCell>
                            <TitleLink to={`/recruitments/${item.id}`}>{item.title}</TitleLink>
                            </TableCell>
                            <TableCell>{item.deadline}</TableCell>
                        </TableRow>
                    ))}
                </tbody>
            </Table>

        </BoardContainer>
    );
};

export default RecruitmentBoard;

