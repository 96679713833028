import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Hello from "./pages/Hello";
import RecruitmentList from "./pages/RecruitmentList";
import GlobalStyle from "./GlobalStyle";
import RecruitmentDetail from "./pages/RecruitmentDetail";
import RegulationList from "./pages/RegulationList";
import RegulationDetail from "./pages/RegulationDetail";
import CommercialAnalysisPage from "./pages/CommercialAnalysisPage";
import CommercialAnalysisDashboard from "./pages/CommercialAnalysisDashboard";

function App() {
  return (
    <BrowserRouter>
      <GlobalStyle /> {/* GlobalStyle 적용 */}
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/" element={<Home />} />
        <Route path="/hello" element={<Hello />} />
        <Route path="/recruitments" element={<RecruitmentList />} />
        <Route path="/recruitments/:id" element={<RecruitmentDetail />} />
        <Route path="/regulations" element={<RegulationList />} />
        <Route path="/regulations/:id" element={<RegulationDetail />} />
        <Route path="/commercial" element={<CommercialAnalysisPage />} />
        <Route
          path="/commercial/:district"
          element={<CommercialAnalysisDashboard />}
        />

        <Route path="*" element={<h1>404 Not Found</h1>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
