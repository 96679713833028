//공모 게시물 리스트 조회
import { apiClient } from "../apiClient";

export const getCommercialAnalysis = async (district) => {
  try {
    const response = await apiClient.get(`/commercial?district=${district}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching commercial analysis:", error);
    throw error;
  }
};

export const getSalesByBusinessCode = async (district) => {
  try {
    const response = await apiClient.get(
      `/commercial/sales-by-business-code?dong=${district}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching sales by business code:", error);
    throw error;
  }
};

export const getSalesBySexAge = async (district) => {
  try {
    const response = await apiClient.get(
      `/commercial/sales-by-sex-age?dong=${district}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching sales by sex and age:", error);
    throw error;
  }
};

export const getSalesByDayOfWeek = async (district) => {
  try {
    const response = await apiClient.get(
      `/commercial/sales-by-day-of-week?dong=${district}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching sales by day of week:", error);
    throw error;
  }
};

export const getSalesByTime = async (district) => {
  try {
    const response = await apiClient.get(
      `/commercial/sales-by-time?dong=${district}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching sales by time:", error);
    throw error;
  }
};

export const getSalesByMonth = async (district) => {
  try {
    const response = await apiClient.get(
      `/commercial/sales-by-month?dong=${district}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching sales by month:", error);
    throw error;
  }
};

