import React from 'react';
import '../css/Home.css';
import Header from '../components/Header';
import HeroSection from '../components/HeroSection';
import About from '../components/About';
import Contest from '../components/Contest';
import Services from '../components/Services';
import Footer from '../components/Footer';

function Home() {
  return (
    <div class="Home">
      <Header />
      <HeroSection />
      <About />
      <Services />
      <Contest />
      <Footer />
    </div>
  );
}

export default Home;