import React from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import styled from "styled-components";
const Logo = styled(Link)`
  display: flex; /* 로고 정렬을 위해 flex 사용 */
  align-items: center;
  color: white;
  text-decoration: none;
  font-size: 36px;
  font-weight: bold;
`;

const LogoImage = styled.img`
  height: 50px; /* 이미지 높이를 네비게이션 높이보다 살짝 작게 */
  margin-right: 20px; /* 로고 이미지와 텍스트 간 간격 */
`;

const NavbarContainer = styled.nav`
  display: flex;
  align-items: center;
  background-color: #000000;
  padding: 30px 50px;
`;
function Header() {
  return (
    <NavbarContainer>
      <nav>
        <Logo>
          <LogoImage src="/LOGO.PNG" alt="TruckSiteFinder Logo" />
        </Logo>
        <ul>
          <li>
            <Link to="/recruitments">최근 공모</Link>
          </li>
          <li>
            <Link to="/commercial">상권 분석 정보</Link>
          </li>
          <li>
            <Link to="/regulations">규제 정보</Link>
          </li>
        </ul>
      </nav>
    </NavbarContainer>
  );
}

export default Header;
