import React, { useEffect, useState } from "react";
import { Radar } from "react-chartjs-2";
import "chart.js/auto";
import { getSalesBySexAge } from "../../api/commercial/commercialApi";

const GenderAgeSalesRadarChart = ({ district }) => {
  const [chartData, setChartData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getSalesBySexAge(district);
        const data = await response;

        // 데이터 처리: "60대 이상"으로 그룹화
        const processAgeGroupSales = (ageGroupSales) => {
          const groupedSales = { ...ageGroupSales };
          groupedSales["60대 이상"] =
            (groupedSales["60대"] || 0) + (groupedSales["70대"] || 0);
          delete groupedSales["60대"];
          delete groupedSales["70대"];
          return groupedSales;
        };

        const femaleSalesData = processAgeGroupSales(data[0].ageGroupSales); // 여성 데이터
        const maleSalesData = processAgeGroupSales(data[1].ageGroupSales); // 남성 데이터

        // 라벨 및 데이터 생성
        const labels = Object.keys(femaleSalesData); // 나이대 라벨
        const femaleSales = Object.values(femaleSalesData); // 여성 매출 데이터
        const maleSales = Object.values(maleSalesData); // 남성 매출 데이터

        // 차트 데이터 설정
        setChartData({
          labels: labels,
          datasets: [
            {
              label: "여성",
              data: femaleSales,
              backgroundColor: "rgba(217, 102, 26, 0.2)", // 연한 주황
              borderColor: "#D9601A", // 진한 주황
              borderWidth: 2,
              pointBackgroundColor: "#D9601A",
              pointBorderColor: "#fff",
              pointHoverRadius: 6,
            },
            {
              label: "남성",
              data: maleSales,
              backgroundColor: "rgba(69, 123, 157, 0.2)", // 연한 파랑
              borderColor: "#457B9D", // 진한 파랑
              borderWidth: 2,
              pointBackgroundColor: "#457B9D",
              pointBorderColor: "#fff",
              pointHoverRadius: 6,
            },
          ],
        });
      } catch (error) {
        console.error("Error fetching gender and age group sales data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [district]);

  if (isLoading) return <p>Loading...</p>;
  if (!chartData) return <p>No data available</p>;

  return (
    <Radar
      data={chartData}
      options={{
        responsive: true,
        maintainAspectRatio: false, // 부모 크기에 맞추기
        plugins: {
          legend: {
            position: "top",
            labels: {
              font: {
                size: 14,
                family: "Arial, sans-serif",
              },
              color: "#4A4A4A",
            },
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                const value = context.raw || 0;
                return `${context.label}: ${value.toLocaleString()}원`;
              },
            },
            backgroundColor: "#333333",
            titleFont: {
              size: 16,
              family: "Arial, sans-serif",
              weight: "bold",
            },
            bodyFont: {
              size: 14,
              family: "Arial, sans-serif",
            },
            borderWidth: 1,
            borderColor: "#FFFFFF",
          },
        },
        scales: {
          r: {
            ticks: {
              stepSize: 15000000000, // 격자 간격 설정 (5억 단위로 설정 예시)
              display: false, // 격자선 수치 숨기기
            },
            grid: {
              color: "rgba(200, 200, 200, 0.3)", // 부드러운 격자선
            },
            angleLines: {
              color: "rgba(200, 200, 200, 0.5)", // 부드러운 축선
            },
            pointLabels: {
              font: {
                size: 14,
              },
              color: "#000000", // 나이대 레이블 색상
            },
          },
        },
      }}
    />
  );
};

export default GenderAgeSalesRadarChart;
