import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import MapContent from "../components/commercial/MapContent";
import Navbar from "../components/Navbar";
import CommercialAnalysisDashboard from "./CommercialAnalysisDashboard";
import { getRecruitentByDong } from "../api/recruitment/recruitmentApi";
import { Link } from "react-router-dom";
import { getTouristAttractions } from "../api/tourApi";
import TouristAttractions from "../components/commercial/TouristAttractions";
import { districtDescriptions } from "../api/commercial/districtDescriptions";
const StyledLink = styled(Link)`
  text-decoration: none; /* 밑줄 제거 */
  color: inherit; /* 부모 요소의 색상 상속 */
  &:hover {
    color: #d9601a; /* 호버 시 원하는 색상 설정 */
  }
`;

// const Column = styled.div`
//   background: #ffffff;
//   border-radius: 10px;
//   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
//   padding: 20px;
//   height: 380px;
//   overflow-y: auto;
//   box-sizing: border-box;
//   font-size: 16px;
// `;
// Styled components
const Container = styled.div`
  display: flex;
  width: 90%;
  margin: 2rem auto;
  gap: 2rem;
`;

const MapContainer = styled.div`
  flex: 2;
  height: 80vh;
`;

const AnalysisContainer = styled.div`
  flex: 1;
  padding: 1rem;
  background-color: #f9f9f9;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  height: 80vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Title = styled.h2`
  font-size: 2rem;
  color: #333;
  margin-bottom: 1rem;
`;
const SubTitle = styled.h3`
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;

  margin-top: 20px;
`;
const ContestHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

// const MoreLink = styled.span`
//   font-size: 0.9rem;
//   color: #457b9d;
//   cursor: pointer;
//   text-decoration: underline;

//   &:hover {
//     color: #1d3557;
//   }
// `;

const ContestList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 1rem 0;
`;

const ContestItem = styled.li`
  background-color: #ffffff;
  padding: 0.8rem;
  margin-bottom: 0.5rem;
  border-radius: 6px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 0.9rem;
  color: #333;
`;

const StyledButton = styled.button`
  margin-top: 1rem;
  padding: 1rem 2rem;
  background-color: #d46618;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: #1d3557;
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(0);
  }
`;

const ScrollToTopButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 1rem;
  background-color: #d46618;
  color: #fff;
  border: none;
  border-radius: 50%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  font-size: 1.2rem;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: #1d3557;
    transform: scale(1.1);
  }
`;
const CommercialAnalysisPage = () => {
  const [selectedRegion, setSelectedRegion] = useState(""); // 선택한 행정동을 저장할 상태
  const [contestList, setContestList] = useState([]); // 공모 목록 상태
  const dashboardRef = useRef(null); // 대시보드 참조 객체
  const [showScrollToTop, setShowScrollToTop] = useState(false); // "위로 올라가기" 버튼 표시 여부
  const [regionDescription, setRegionDescription] = useState(""); // 행정동 설명 저장
  const [touristAttractions, setTouristAttractions] = useState([]); // 주변 관광지 목록

  // 행정동 선택 시 콜백 함수
  const handleLocationSelect = (loc) => {
    setSelectedRegion(loc.regionName); // 선택된 행정동 이름을 상태로 저장
    console.log("Selected region:", loc.regionName);

    getRecruitentByDong(loc.regionName).then((res) => {
      console.log(res);
      setContestList(res.recruitments);
    });

    // 해당 행정동 설명 찾기
    const description = districtDescriptions.find(
      (desc) => desc.name === loc.regionName
    )?.description;
    setRegionDescription(description || "해당 지역에 대한 설명이 없습니다.");

    //관광지정보 가져오기
    console.log(loc.x, loc.y);
    getTouristAttractions(loc.x, loc.y)
      .then((res) => {
        setTouristAttractions(res);
      })
      .catch((error) => {
        console.error("Error fetching tourist attractions:", error);
      });
  };

  // "자세한 상권 분석 하기" 버튼 클릭 시 스크롤 이동
  const handleAnalysisButtonClick = () => {
    if (dashboardRef.current) {
      dashboardRef.current.scrollIntoView({ behavior: "smooth" }); // 부드러운 스크롤
    }
  };

  // "위로 올라가기" 버튼 클릭 시 스크롤 상단 이동
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // 스크롤 이벤트로 "위로 올라가기" 버튼 표시 제어
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Navbar />
      <Container>
        {/* 왼쪽 지도 영역 */}
        <MapContainer>
          <MapContent onLocationSelect={handleLocationSelect} />
        </MapContainer>

        {/* 오른쪽 분석 데이터 영역 */}
        <AnalysisContainer>
          <div>
            {selectedRegion ? (
              <>
                <Title>{selectedRegion} 상권</Title>
                {/* 공모 목록 */}
                <ContestHeader>
                  <SubTitle>진행 중인 공모</SubTitle>
                </ContestHeader>
                <ContestList>
                  {contestList && contestList.length > 0 ? (
                    contestList.map((contest) => (
                      <ContestItem key={contest.id}>
                        <StyledLink to={`/recruitments/${contest.id}`}>
                          {contest.title}
                        </StyledLink>
                      </ContestItem>
                    ))
                  ) : (
                    <ContestItem>존재하는 공모가 없습니다</ContestItem>
                  )}
                </ContestList>
                {/* 행정동 설명 */}
                <SubTitle>{selectedRegion}</SubTitle>
                <p>{regionDescription}</p>
                {/* 주변 관광지 */}
                <TouristAttractions attractions={touristAttractions} />
              </>
            ) : (
              <p>지도를 클릭하여 상권 분석 데이터를 확인하세요.</p>
            )}
          </div>

          {/* 상권 분석 보기 버튼 */}
          {selectedRegion && (
            <StyledButton onClick={handleAnalysisButtonClick}>
              자세한 상권 분석 하기
            </StyledButton>
          )}
        </AnalysisContainer>
      </Container>

      {/* 대시보드 영역 */}
      {selectedRegion && (
        <div ref={dashboardRef}>
          <CommercialAnalysisDashboard district={selectedRegion} />
        </div>
      )}
      {/* 위로 올라가기 버튼 */}
      {showScrollToTop && (
        <ScrollToTopButton onClick={handleScrollToTop}>↑</ScrollToTopButton>
      )}
    </>
  );
};

export default CommercialAnalysisPage;
