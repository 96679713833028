import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import { getSalesByMonth } from "../../api/commercial/commercialApi";

const MonthlySalesLineChart = ({ district }) => {
  const [chartData, setChartData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getSalesByMonth(district);
        const data = response || [];

        const months = Array.from(
          new Set(data.map((item) => parseInt(item.month, 10))) // 문자열을 숫자로 변환
        ).sort((a, b) => a - b); // 숫자 기준으로 정렬

        const cafeSales = data
          .filter((item) => item.category === "카페")
          .map((item) => item.salesAmount);
        const restaurantSales = data
          .filter((item) => item.category === "음식점")
          .map((item) => item.salesAmount);

        setChartData({
          labels: months,
          datasets: [
            {
              label: "카페",
              data: cafeSales,
              borderColor: "#D9601A",
              backgroundColor: "rgba(217, 102, 26, 0.2)", // 옅은 주황색
              fill: true,
              tension: 0.4,
            },
            {
              label: "음식점",
              data: restaurantSales,
              borderColor: "#457B9D",
              backgroundColor: "rgba(69, 123, 157, 0.2)", // 옅은 파란색
              fill: true,
              tension: 0.4,
            },
          ],
        });
      } catch (error) {
        console.error("Error fetching monthly sales data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [district]);

  if (isLoading) return <p>Loading...</p>;
  if (!chartData) return <p>No data available</p>;

  return (
    <Line
      data={chartData}
      options={{
        responsive: true,
        maintainAspectRatio: false, // 부모 박스 크기에 맞추기

        plugins: {
          legend: {
            position: "top",
            labels: {
              font: {
                size: 14,
                family: "Arial, sans-serif",
              },
              color: "#4A4A4A",
            },
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                const value = context.raw || 0;
                return `${context.label}: ${value.toLocaleString()}원`;
              },
            },
            backgroundColor: "#333333",
            titleFont: {
              size: 16,
              family: "Arial, sans-serif",
              weight: "bold",
            },
            bodyFont: {
              size: 14,
              family: "Arial, sans-serif",
            },
            borderWidth: 1,
            borderColor: "#FFFFFF",
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: "월",
              color: "#4A4A4A",
              font: {
                size: 14,
              },
            },
            grid: {
              drawOnChartArea: false,
            },
          },
          y: {
            title: {
              display: true,
              text: "매출 금액 (원)",
              color: "#4A4A4A",
              font: {
                size: 14,
              },
            },
            ticks: {
              // callback: function (value) {
              //   return `${value.toLocaleString()}원`;
              // },
              display: false,
            },
          },
        },
      }}
    />
  );
};

export default MonthlySalesLineChart;
